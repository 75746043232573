import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';

import { Autocomplete, Box, IconButton, TableRow, Typography } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

import { Dropdown } from '@/components/atoms/Dropdown';
import { OFFSET } from '@/constants/sizes';
import { TableTemplateWithoutStore } from '@/components/Templates/TableTemplateWithoutStore';
import {
  TableCellStyled,
  TableCellStyledWithoutPadding,
  TableRowStyledWithoutPadding,
} from '@/components/Templates/TableComponentStyled';
import { LabelLikeHintWithText } from '@/components/common/LabelLikeHintWithText';
import { TYPE_PARAMS } from '@/constants';
import { useRootStore } from '@/stores';
import { ModalDelete } from '@/components/common/ModalDelete';
import { useModalDelete } from '@/components/common/hooks/useModalDelete';
import type { AssessmentTemplateAttrModelProps } from '@/stores/Models/AssessmentTemplateAttrModel';
import type { AssessmentTemplateAttributeModel } from '@/stores/Models/AdministrationAssessmentTemplateAttributeModel';
import { LogicalEvaluation } from '@/components/Administering/ModelEvaluation/ModelEvaluationDetail/ModelEvaluationDetailTab/ModelEvaluationDetailTabAttributes/components/LogicalEvaluation';
import { EvaluationForExceptions } from '@/components/Administering/ModelEvaluation/ModelEvaluationDetail/ModelEvaluationDetailTab/ModelEvaluationDetailTabAttributes/components/EvaluationForExceptions';
import { Weight } from '@/components/Administering/ModelEvaluation/ModelEvaluationDetail/ModelEvaluationDetailTab/ModelEvaluationDetailTabAttributes/components/Weight';
import { MarksToField } from '@/components/Administering/ModelEvaluation/ModelEvaluationDetail/ModelEvaluationDetailTab/ModelEvaluationDetailTabAttributes/components/Marks';
import { SimpleInputCustom } from '@/components/Administering/ModelEvaluation/ModelEvaluationDetail/ModelEvaluationDetailTab/ModelEvaluationDetailTabAttributes/components/SimpleInputCustom';
import { TextFieldStyled } from '@/components/atoms/TextFieldStyled';
import { color } from '@/constants/colors';
import { IAssessmentTemplateAttributeModelStore } from '@/stores/type';

const EditButton = observer(
  ({
    handleSave,
    handleClose,
    selectedAttributeSet,
    isStatusDisabled,
  }: {
    handleSave: (val: any) => void;
    handleClose: (val: any) => void;
    selectedAttributeSet?: AssessmentTemplateAttributeModel;
    isStatusDisabled: boolean;
  }) => {
    const templateAttrVal = selectedAttributeSet && Object.values(selectedAttributeSet?.templateAttrs);

    if (
      !selectedAttributeSet ||
      (Object.values(selectedAttributeSet.fieldChanged)?.every((value) => !value) &&
        templateAttrVal?.every((item) => Object.values(item.fieldChanged)?.every((value) => !value)) &&
        selectedAttributeSet.allAttrWithoutId?.length === 0) ||
      isStatusDisabled
    ) {
      return <Box />;
    }

    return (
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IconButton color="success" aria-label="confirm" onClick={handleSave}>
          <CheckOutlinedIcon />
        </IconButton>
        <IconButton color="error" aria-label="confirm" onClick={handleClose}>
          <HighlightOffOutlinedIcon />
        </IconButton>
      </Box>
    );
  },
);

const likeTableHeader = (
  handleSave: (val: any) => void,
  handleClose: (val: any) => void,
  isStatusDisabled: boolean,
  selectedAttributeSet?: AssessmentTemplateAttributeModel,
) => [
  'Параметры',
  '',
  '',
  <EditButton
    isStatusDisabled={isStatusDisabled}
    key={new Date().getTime()}
    handleSave={handleSave}
    handleClose={handleClose}
    selectedAttributeSet={selectedAttributeSet}
  />,
];

export const TYPE_PARAMS_TO_DISPLAYNAME = {
  [TYPE_PARAMS.Interval]: 'Диапазон',
  [TYPE_PARAMS.Categorical]: 'Категории',
  [TYPE_PARAMS.Helper]: 'Справочное поле',
  [TYPE_PARAMS.Logical]: 'Логическое поле',
} as const;

export const ModelEvaluationAttributeTabParams = observer(
  ({
    selectedAttributeSet,
    store,
    isStatusDisabled,
  }: {
    store: IAssessmentTemplateAttributeModelStore;
    selectedAttributeSet?: AssessmentTemplateAttributeModel;
    isStatusDisabled: boolean;
  }) => {
    const { id: idData } = useParams<{ id: string }>();
    const { open, handleClose, handleOpen, deletedValue } =
      useModalDelete<AssessmentTemplateAttrModelProps>(isStatusDisabled);
    const { isLoading, administrationAssessmentTemplateStore } = useRootStore();

    const dataForDropdown = useMemo(() => {
      const values = administrationAssessmentTemplateStore.dataContext.map(({ name }) => ({
        label: name,
        value: name,
      }));

      return [{ label: 'Вводится вручную', value: '' }, ...values];
    }, [administrationAssessmentTemplateStore.dataContext]);

    const mainLoader = isLoading || store.isLoading || store.isLoadingAttribute || store.isLoadingPatch;

    useEffect(() => {
      if (selectedAttributeSet) {
        handleClear();
      }
    }, [selectedAttributeSet]);

    if (!selectedAttributeSet) {
      return null;
    }

    const handleSave = () => {
      if (selectedAttributeSet) {
        const {
          value_type,
          data_obj_attr_name,
          weight,
          is_boolean_score,
          out_of_range_score,
          out_of_range_bool_score,
          tags,
        } = selectedAttributeSet || {};

        const asmnt_tmpl_attr = Object.values(selectedAttributeSet.fieldChanged).some((item) => item)
          ? {
              value_type,
              data_obj_attr_name,
              weight: +weight || 0,
              is_boolean_score,
              out_of_range_score: +out_of_range_score || 0,
              out_of_range_bool_score,
              tags,
            }
          : {};

        const asmnt_tmpl_attr_val = selectedAttributeSet.templateAttrs
          ?.map((attrVal) => {
            if (
              (!attrVal.id &&
                selectedAttributeSet.value_type === TYPE_PARAMS.Logical &&
                selectedAttributeSet.is_boolean_score) ||
              Object.values(attrVal.fieldChanged).some((item) => item)
            ) {
              return {
                id: attrVal?.id,
                sequence: attrVal.sequence,
                boolean_value: attrVal?.boolean_value,
                category_value: attrVal?.category_value,
                interval_start_value: !attrVal?.interval_start_value ? null : +attrVal?.interval_start_value,
                interval_end_value: !attrVal?.interval_end_value ? null : +attrVal?.interval_end_value,
                // @ts-ignore
                score: +attrVal?.score ?? 0,
                boolean_score: attrVal?.boolean_score,
                asmnt_tmpl_attr: attrVal?.id ? attrVal.asmnt_tmpl_attr : selectedAttributeSet?.id,
              };
            }
          })
          .filter((item) => item);

        let mark_as_delete_ids: number[] = [];

        if (value_type !== selectedAttributeSet?._value_type) {
          const allIdsEdit = asmnt_tmpl_attr_val?.map((item) => item?.id)?.filter((item) => item);
          const allIdsSubItems = selectedAttributeSet.templateAttrs.map(({ id }) => id).filter((item) => item);
          // @ts-ignore
          mark_as_delete_ids = allIdsSubItems.filter((id) => !allIdsEdit.includes(id));
        }

        if (idData) {
          store.updateAssessmentTemplateAllValues({
            pageId: idData,
            selectedAttributeId: selectedAttributeSet.id,
            asmnt_tmpl_attr,
            asmnt_tmpl_attr_val,
            mark_as_delete_ids,
          });
        }
      }
    };

    const handleClear = () => {
      if (selectedAttributeSet) {
        selectedAttributeSet.deleteAllAttrWithoutId();
        selectedAttributeSet.restore();
        selectedAttributeSet?.templateAttrs?.forEach((item) => {
          item.restore();
        });
      }
    };

    const handleDelete = () => {
      if (deletedValue?.id && idData) {
        store.deleteTemplateAttrVal({ pageId: idData, deleteId: deletedValue.id });
      }
      handleClose();
    };

    const header = likeTableHeader(handleSave, handleClear, isStatusDisabled, selectedAttributeSet);

    const deleteInfo = () => {
      if (selectedAttributeSet.is_boolean_score && selectedAttributeSet.value_type === TYPE_PARAMS.Logical) {
        return [
          ['Оценка: ', deletedValue?.boolean_score],
          ['Логическое значение: ', deletedValue?.boolean_value],
        ];
      }
      if (!selectedAttributeSet.is_boolean_score && selectedAttributeSet.value_type === TYPE_PARAMS.Interval) {
        return [
          ['Оценка: ', deletedValue?.score],
          ['Минимальное: ', deletedValue?.interval_start_value],
          ['Максимальное: ', deletedValue?.interval_end_value],
        ];
      }

      return [
        ['Оценка: ', deletedValue?.score],
        ['Значение: ', deletedValue?.category_value],
      ];
    };

    return (
      <>
        <TableTemplateWithoutStore<AssessmentTemplateAttributeModel>
          isHideBorders
          tableHeader={header}
          RenderComponent={(props) => (
            <RenderRowTabParamsTable
              dataForDropdown={dataForDropdown}
              openDeleteModal={handleOpen}
              mainLoader={mainLoader || isStatusDisabled}
              {...props}
            />
          )}
          store={{
            renderedRows: [selectedAttributeSet],
          }}
          isLoading={isLoading || administrationAssessmentTemplateStore.isLoading}
        />
        <ModalDelete
          uniqId="Data_modal"
          open={open && !isStatusDisabled}
          handleClose={handleClose}
          handleDelete={handleDelete}
          title="Вы действительно хотите удалить значение атрибута?"
          // @ts-ignore
          descriptions={deleteInfo()}
        />
      </>
    );
  },
);

const RenderRowTabParamsTable = observer(
  ({
    row,
    dataForDropdown,
    openDeleteModal,
    mainLoader,
  }: {
    row: AssessmentTemplateAttributeModel;
    dataForDropdown: { label: string; value: string }[];
    openDeleteModal: (val: any) => void;
    mainLoader: boolean;
  }) => {
    const handleChangeDropdown = (name: string, value: string) => {
      (row as unknown as any)[name] = value;
    };

    const part = renderPartByType({
      data: row,
      openDeleteModal,
      mainLoader,
    });

    const initValAutocomplete = useMemo(
      () => dataForDropdown?.find(({ value }) => value === row?.data_obj_attr_name),
      [dataForDropdown, row?.data_obj_attr_name],
    );

    return (
      <>
        <TableRow>
          <TableCellStyled>
            <LabelLikeHintWithText title="Значения">
              <Dropdown
                mb={0}
                forceSet
                disabled={mainLoader}
                dropdownWidth="160px"
                onChange={handleChangeDropdown}
                key="value_type"
                label=""
                title=""
                id="value_type"
                sx={{ backgroundColor: row.fieldChanged.value_type ? color.light : color.transparent }}
                value={row?.value_type}
                dropdownItems={[
                  {
                    label: TYPE_PARAMS_TO_DISPLAYNAME.Interval,
                    value: TYPE_PARAMS.Interval,
                  },
                  {
                    label: TYPE_PARAMS_TO_DISPLAYNAME.Categorical,
                    value: TYPE_PARAMS.Categorical,
                  },
                  {
                    label: TYPE_PARAMS_TO_DISPLAYNAME.Helper,
                    value: TYPE_PARAMS.Helper,
                  },
                  {
                    label: TYPE_PARAMS_TO_DISPLAYNAME.Logical,
                    value: TYPE_PARAMS.Logical,
                  },
                ]}
              />
            </LabelLikeHintWithText>
          </TableCellStyled>
          {part.firstRow}
        </TableRow>

        <TableRow>
          <TableCellStyled colSpan={2}>
            <LabelLikeHintWithText title="Данные">
              <AutocompleteData
                dataForDropdown={dataForDropdown}
                onChange={handleChangeDropdown}
                initialValue={initValAutocomplete}
                isFieldChanged={row.fieldChanged.data_obj_attr_name}
                mainLoader={mainLoader}
              />
            </LabelLikeHintWithText>
          </TableCellStyled>
          {part.secondRow}
        </TableRow>
        <TableRow>{part.thirdRow}</TableRow>
      </>
    );
  },
);

const AutocompleteData = React.memo(
  ({
    onChange,
    dataForDropdown,
    initialValue,
    isFieldChanged,
    mainLoader,
  }: {
    initialValue?: { label: string; value: string };
    onChange?: (name: string, value: string) => void;
    dataForDropdown?: { label: string; value: string }[];
    isFieldChanged: boolean;
    mainLoader: boolean;
  }) => {
    const handleSelectedValue = (event: any, newValue: { label: string; value: string } | null) => {
      if ((newValue?.value || newValue?.value === '') && onChange) {
        onChange('data_obj_attr_name', newValue.value);
      }
    };

    return (
      <Autocomplete
        disabled={mainLoader}
        options={dataForDropdown ?? []}
        openOnFocus
        autoComplete
        blurOnSelect
        sx={{ backgroundColor: isFieldChanged ? color.light : color.transparent }}
        id="data_obj_attr_name"
        value={initialValue}
        onChange={handleSelectedValue}
        renderInput={(params) => <TextFieldStyled {...params} label="" sx={{ padding: 0 }} size="small" />}
      />
    );
  },
);

export const BOOLEAN_LOWER = {
  true: 'true',
  false: 'false',
} as const;

export const BOOLEAN_LOWER_MAP_TO_DISPLAYNAME = {
  [BOOLEAN_LOWER.true]: 'Истина',
  [BOOLEAN_LOWER.false]: 'Ложь',
} as const;

const RenderRow = observer(
  ({
    row,
    openDeleteModal,
    data,
    disabled,
  }: {
    row: AssessmentTemplateAttrModelProps;
    openDeleteModal: (value: Record<string, any>) => void;
    data: AssessmentTemplateAttributeModel;
    disabled: boolean;
  }) => {
    const onChangeDropdownWithSave = <T extends any>(name: string, value: T) => {
      // @ts-ignore
      row[name] = value;
    };

    const onChangeInputWithSave = (value: Record<string, string>) => {
      // @ts-ignore
      row[Object.keys(value)[0]] = Object.values(value)[0];
    };

    const deleteItem = () => {
      if (!row.id) {
        data.deleteTemplateAttrBySequence(row.sequence);
        return;
      }
      openDeleteModal(row);
    };

    const isTheSameValueType = data.value_type === data._value_type;

    if (!isTheSameValueType && row.id) {
      return <></>;
    }

    if (data.value_type === TYPE_PARAMS.Logical) {
      return (
        <TableRowStyledWithoutPadding key={`${row.id}_${row.sequence}`}>
          <TableCellStyledWithoutPadding verticalAlign="middle">
            <Dropdown
              disabled={disabled}
              mb={0}
              dropdownWidth="80%"
              onChange={onChangeDropdownWithSave}
              key="boolean_value"
              label=""
              title=""
              id="boolean_value"
              sx={{ backgroundColor: row.fieldChanged.boolean_value || !row.id ? color.light : color.transparent }}
              value={row?.boolean_value ?? false}
              dropdownItems={[
                {
                  label: BOOLEAN_LOWER_MAP_TO_DISPLAYNAME.false,
                  value: false,
                },
                {
                  label: BOOLEAN_LOWER_MAP_TO_DISPLAYNAME.true,
                  value: true,
                },
              ]}
            />
          </TableCellStyledWithoutPadding>
          <TableCellStyledWithoutPadding>
            {data.is_boolean_score ? (
              <Dropdown
                disabled={disabled}
                mb={0}
                dropdownWidth="80%"
                onChange={onChangeDropdownWithSave}
                key="boolean_score"
                label=""
                title=""
                id="boolean_score"
                sx={{ backgroundColor: row.fieldChanged.boolean_score || !row.id ? color.light : color.transparent }}
                value={Boolean(row?.boolean_score)}
                dropdownItems={[
                  {
                    label: BOOLEAN_LOWER_MAP_TO_DISPLAYNAME.false,
                    value: false,
                  },
                  {
                    label: BOOLEAN_LOWER_MAP_TO_DISPLAYNAME.true,
                    value: true,
                  },
                ]}
              />
            ) : (
              <SimpleInputCustom
                typeVal="number"
                disabled={disabled}
                sx={{ backgroundColor: row.fieldChanged.score || !row.id ? color.light : color.transparent }}
                fieldName="score"
                initialVal={row.score}
                value={row.score}
                onChange={onChangeInputWithSave}
              />
            )}
          </TableCellStyledWithoutPadding>
          <TableCellStyledWithoutPadding align="right">
            <Box sx={{ backgroundColor: color.white }}>
              <IconButton color="primary" aria-label="удалить" onClick={deleteItem}>
                <DeleteOutlinedIcon color="error" />
              </IconButton>
            </Box>
          </TableCellStyledWithoutPadding>
        </TableRowStyledWithoutPadding>
      );
    }

    return (
      <TableRowStyledWithoutPadding key={`${row.id}_${row.sequence}`}>
        <TableCellStyledWithoutPadding>
          <SimpleInputCustom
            typeVal="number"
            disabled={disabled}
            autoFocus={!row.id}
            sx={{ backgroundColor: row.fieldChanged.interval_start_value || !row.id ? color.light : color.transparent }}
            fieldName="interval_start_value"
            initialVal={row.interval_start_value}
            onChange={onChangeInputWithSave}
          />
        </TableCellStyledWithoutPadding>
        <TableCellStyledWithoutPadding>
          <SimpleInputCustom
            typeVal="number"
            disabled={disabled}
            sx={{ backgroundColor: row.fieldChanged.interval_end_value || !row.id ? color.light : color.transparent }}
            fieldName="interval_end_value"
            initialVal={row.interval_end_value}
            onChange={onChangeInputWithSave}
          />
        </TableCellStyledWithoutPadding>
        <TableCellStyledWithoutPadding align="right">
          {data.is_boolean_score ? (
            <Dropdown
              disabled={disabled}
              mb={0}
              dropdownWidth="100%"
              sx={{ backgroundColor: row.fieldChanged.boolean_score || !row.id ? color.light : color.transparent }}
              onChange={onChangeDropdownWithSave}
              key="boolean_score"
              label=""
              title=""
              id="boolean_score"
              value={Boolean(row?.boolean_score)}
              dropdownItems={[
                {
                  label: BOOLEAN_LOWER_MAP_TO_DISPLAYNAME.false,
                  value: false,
                },
                {
                  label: BOOLEAN_LOWER_MAP_TO_DISPLAYNAME.true,
                  value: true,
                },
              ]}
            />
          ) : (
            <SimpleInputCustom
              disabled={disabled}
              typeVal="number"
              sx={{ backgroundColor: row.fieldChanged.score || !row.id ? color.light : color.transparent }}
              fieldName="score"
              initialVal={row.score}
              onChange={onChangeInputWithSave}
            />
          )}
        </TableCellStyledWithoutPadding>
        <TableCellStyledWithoutPadding align="right">
          <Box sx={{ backgroundColor: color.white }}>
            <IconButton color="primary" aria-label="удалить" onClick={deleteItem}>
              <DeleteOutlinedIcon color="error" />
            </IconButton>
          </Box>
        </TableCellStyledWithoutPadding>
      </TableRowStyledWithoutPadding>
    );
  },
);

const RenderTableLikeASlider = observer(
  ({
    data,
    openDeleteModal,
    disabled,
  }: {
    data: AssessmentTemplateAttributeModel;
    openDeleteModal: (value: Record<string, string>) => void;
    disabled: boolean;
  }) => {
    const subData = data.templateAttrs;

    const addCategory = () => {
      data.addTemplateAttrs();
    };

    const header =
      data.value_type === TYPE_PARAMS.Logical
        ? ['Логическое значение', 'Оценка', '']
        : ['Минимальное', 'Максимальное', 'Оценка'];
    return (
      <TableTemplateWithoutStore<AssessmentTemplateAttrModelProps>
        isHideBorders
        isHideBorderslastRowItem
        lastElement
        lastRowItem={
          <>
            <TableRow>
              <TableCellStyled sx={{ padding: 0 }}>
                <Box
                  mt={OFFSET.xxs}
                  mr={OFFSET.xxs}
                  onClick={addCategory}
                  sx={{
                    cursor: 'pointer',
                    width: 36,
                    height: 36,
                    backgroundColor: color.sea_green,
                    color: color.white,
                    borderRadius: '2px',
                    fontSize: '36px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  +
                </Box>
              </TableCellStyled>
            </TableRow>
          </>
        }
        tableHeader={header}
        RenderComponent={(props) => (
          <RenderRow openDeleteModal={openDeleteModal} {...props} data={data} disabled={disabled} />
        )}
        store={{
          renderedRows: subData,
        }}
        isLoading={false}
      />
    );
  },
);

const renderPartByType = ({
  data,
  openDeleteModal,
  mainLoader,
}: {
  data: AssessmentTemplateAttributeModel;
  openDeleteModal: (value: any) => void;
  mainLoader: boolean;
}) => {
  const part = {
    firstRow: <></>,
    secondRow: <></>,
    thirdRow: <></>,
  };

  if (data.value_type === TYPE_PARAMS.Interval || data.value_type === TYPE_PARAMS.Logical) {
    part.firstRow = (
      <>
        <LogicalEvaluation modelData={data} disabled={mainLoader} />
        <EvaluationForExceptions modelData={data} disabled={mainLoader} />
        <Weight modelData={data} disabled={mainLoader} />
      </>
    );

    part.secondRow = (
      <>
        <MarksToField modelData={data} disabled={mainLoader} />
      </>
    );

    part.thirdRow = (
      <TableCellStyled colSpan={4}>
        <RenderTableLikeASlider data={data} openDeleteModal={openDeleteModal} disabled={mainLoader} />
      </TableCellStyled>
    );
  } else if (data.value_type === TYPE_PARAMS.Categorical) {
    part.firstRow = (
      <>
        <LogicalEvaluation modelData={data} disabled={mainLoader} />
        <EvaluationForExceptions disabled={mainLoader} modelData={data} />
        <Weight modelData={data} disabled={mainLoader} />
      </>
    );

    part.secondRow = (
      <>
        <MarksToField modelData={data} disabled={mainLoader} />
      </>
    );

    part.thirdRow = <CategoryItem data={data} openModal={openDeleteModal} disabled={mainLoader} />;
  } else if (data.value_type === TYPE_PARAMS.Helper) {
    part.firstRow = (
      <>
        <TableCellStyled />
      </>
    );

    part.secondRow = (
      <>
        <TableCellStyled colSpan={2} />
      </>
    );

    part.thirdRow = <TableCellStyled colSpan={4} />;
  }

  return part;
};

const CategoryItem = observer(
  ({
    data,
    openModal,
    disabled,
  }: {
    data: AssessmentTemplateAttributeModel;
    openModal: (value: Record<string, any>) => void;
    disabled: boolean;
  }) => {
    const subData = data.templateAttrs;
    const isSameValueType = data.value_type === data._value_type;

    const addCategory = () => {
      data.addTemplateAttrsCategory();
    };

    return (
      <TableCellStyled colSpan={4}>
        <Box sx={{ display: 'flex', width: '100%', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <Typography
              mb="11px"
              sx={{
                fontWeight: 600,
                fontSize: '16px',
                lineHeight: '24px',
                color: color.aquamarine,
                flex: 1,
              }}
            >
              Значение
            </Typography>
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '16px',
                color: color.wet_asphalt,
              }}
            >
              Оценка
            </Typography>
            <Box sx={{ visibility: 'hidden' }}>
              <IconButton color="primary" aria-label="удалить" component="label" onClick={() => {}}>
                <DeleteOutlinedIcon color="error" />
              </IconButton>
            </Box>
          </Box>
          {subData?.map((info, index) => {
            if (!isSameValueType && info?.id) {
              return <></>;
            }

            const onChangeDropdownWithSave = (name: string, value: string) => {
              // @ts-ignore
              info[name] = value;
            };

            const onChangeInputWithSave = (value: Record<string, string>) => {
              // @ts-ignore
              info[Object.keys(value)[0]] = Object.values(value)[0];
            };

            const deleteItem = () => {
              if (!info?.id) {
                data.deleteTemplateAttrBySequence(info.sequence);
                return;
              }

              openModal(info);
            };

            return (
              <Box sx={{ display: 'flex' }} mb="7px" key={`${info?.id}_${info?.sequence}`}>
                <Box sx={{ display: 'flex', flex: 5, alignItems: 'center' }} mr="7px">
                  <SimpleInputCustom
                    typeVal="string"
                    disabled={disabled}
                    required
                    sx={{
                      backgroundColor: info.fieldChanged.category_value || !info.id ? color.light : color.transparent,
                      width: '100%',
                    }}
                    fieldName="category_value"
                    autoComplete="off"
                    size="small"
                    id="category_value"
                    initialVal={info?.category_value}
                    onChange={onChangeInputWithSave}
                    error={false}
                  />
                </Box>
                <Box sx={{ display: 'flex', flex: 1, alignItems: 'center' }}>
                  {data.is_boolean_score ? (
                    <Dropdown
                      disabled={disabled}
                      mb={0}
                      sx={{
                        backgroundColor: info.fieldChanged.boolean_score || !info.id ? color.light : color.transparent,
                        width: '100%',
                      }}
                      onChange={onChangeDropdownWithSave}
                      key="boolean_score"
                      label=""
                      title=""
                      id="boolean_score"
                      value={info?.boolean_score}
                      dropdownItems={[
                        {
                          label: BOOLEAN_LOWER_MAP_TO_DISPLAYNAME.false,
                          value: false,
                        },
                        {
                          label: BOOLEAN_LOWER_MAP_TO_DISPLAYNAME.true,
                          value: true,
                        },
                      ]}
                    />
                  ) : (
                    <SimpleInputCustom
                      disabled={disabled}
                      required
                      typeVal="number"
                      sx={{
                        backgroundColor: info.fieldChanged.score || !info.id ? color.light : color.transparent,
                        width: '100%',
                      }}
                      fieldName="score"
                      autoComplete="off"
                      size="small"
                      id="score"
                      initialVal={info?.score}
                      onChange={onChangeInputWithSave}
                      error={false}
                    />
                  )}
                </Box>
                <Box sx={{ backgroundColor: color.white }}>
                  <IconButton color="primary" aria-label="удалить" onClick={deleteItem}>
                    <DeleteOutlinedIcon color="error" />
                  </IconButton>
                </Box>
              </Box>
            );
          })}
          <Box
            mr={OFFSET.xxs}
            onClick={addCategory}
            sx={{
              cursor: 'pointer',
              width: 36,
              height: 36,
              backgroundColor: color.sea_green,
              color: color.white,
              borderRadius: '2px',
              fontSize: '36px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            +
          </Box>
        </Box>
      </TableCellStyled>
    );
  },
);
