import React, { useState } from 'react';

import { color } from '@/constants/colors';
import { Box, FormControl, ListItemText, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import { InputBaseOutlinedStyled } from '../TextFieldStyled';

type ItemsType = {
  [key: string]: string;
};

type SelectProps = {
  defValue: string;
  items: ItemsType;
  title?: React.ReactNode;
  disabled?: boolean;
  onChange?: (value: string) => void;
  [k: string]: any;
};

export const nonClickableTags = ['SELECT', 'SPAN', 'DIV', 'LI', 'P', 'UL'];

export const SelectStyled = ({ defValue, items, title, disabled, onChange, ...props }: SelectProps) => {
  const [problemLevel, setProblemLevel] = useState('');

  const handleChange = (event: SelectChangeEvent) => {
    const newValue = event.target.value as string;
    setProblemLevel(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const getBackgroundColor = (level: string) => {
    switch (level) {
      case 'Зеленая зона':
        return '#EBFFF5';
      case 'Желтая зона':
        return '#fff9ea';
      case 'Красная зона':
        return '#ffeaef';
      case 'Черная зона':
        return '#e8e8e8';
      default:
        return 'white';
    }
  };

  const getBorderColor = (level: string) => {
    switch (level) {
      case 'Зеленая зона':
        return '#21C06C';
      case 'Желтая зона':
        return '#FEC127';
      case 'Красная зона':
        return '#FF1049';
      case 'Черная зона':
        return '#1C1C1C';
      default:
        return color.gray;
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: props.justifyContent ? `${props.justifyContent}` : 'center', m: 0 }}>
      <FormControl sx={{ m: 0, minWidth: 124 }}>
        <Select
          value={problemLevel}
          onChange={handleChange}
          displayEmpty
          disabled={disabled || Object.keys(items).length === 0}
          input={
            <InputBaseOutlinedStyled
              style={{
                borderColor: problemLevel ? getBorderColor(problemLevel) : getBorderColor(defValue),
                backgroundColor: problemLevel ? getBackgroundColor(problemLevel) : getBackgroundColor(defValue),
              }}
              disabled
              readOnly={!onChange}
            />
          }
          renderValue={() => {
            return (
              <ListItemText
                primary={defValue}
                primaryTypographyProps={{ style: { fontSize: '12px', textAlign: 'center' } }}
              >
                {defValue}
              </ListItemText>
            );
          }}
          MenuProps={{
            PaperProps: {
              style: {
                marginTop: '8px',
              },
            },
          }}
          sx={{
            fontSize: '12px',
            padding: '0',
          }}
          IconComponent={() => null}
        >
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: 600,
              fontFamily: 'Raleway',
              lineHeight: '14px',
              padding: '8px 13px',
            }}
          >
            {title}
          </Typography>
          {Object.entries(items).map(([key, value]) => (
            <MenuItem key={key} value={value.toString()} sx={{ display: 'flex', justifyContent: 'center' }}>
              <ListItemText
                primary={value}
                primaryTypographyProps={{ style: { fontSize: '12px', textAlign: 'center' } }}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
