import { observable, makeObservable, action, reaction, runInAction } from 'mobx';
import { apiPath } from '@/constants/api';
import { createAxiosRequest } from '@/utils/request';
import type { TableStoreType } from '@/stores/TableStore';
import { TableStore } from '@/stores/TableStore';
import type { IRootStore } from '@/stores/type';
import type { IButtonGroupItem } from '@/constants/types';
import { DOCUMENT_TYPE, DOCUMENT_TYPE_SWOP } from '@/constants/backendEnum';

export type DocumentsFile = {
  user_id: number;
  file_date: string;
  file_id: string;
  file_name: string;
};

export type TableBodyDocuments = {
  document_id_vid: number;
  name_vid: string;
  files: DocumentsFile[];
};

export type TableBodyDocumentsApplications = {
  id: number;
  count: number;
  result: TableBodyDocuments[];
};

export type GetDocuments = {
  count: number;
  result: (TableBodyDocuments | TableBodyDocumentsApplications)[];
};

export type GetDocumentsType = {
  [type: string]: {
    [typeItem: string]: string;
  };
};

export type DocumentsStoreType = {
  rootStore: IRootStore;
  contragentId: string;
  modelId: string;
  sourceContext?: 'application' | 'contragent';
  documentsDropdownValue?: { label: string; value: string }[][] | undefined;
  documentsSelectValue?: { [key: string]: string }[] | undefined;
  selectedFilter?: IButtonGroupItem<string>;
  openRows?: Set<string>;
  selectedRows?: Set<string>;
  selectedDocumentsType?: string;
  selectedCreatedDateGte?: string;
  selectedCreatedDateLte?: string;
  selectedOrdering?: string;
  toggleRow: (id: string) => void;
  toggleRowSelection: (id: string) => void;
  getDocumentsById: (id: string, modelId: string, immediately?: boolean) => Promise<void>;
  setContragentId: (id: string) => string;
  setModelId: (modelId: string) => string;
  setSourceContext: (sourceContext: 'application' | 'contragent') => void;
  getDocumentsType: () => void;
  createDocuments: ({
    id,
    modelId,
    userId,
    documentType,
    documentKind,
    file,
  }: {
    id: string;
    modelId: string;
    userId: number;
    documentType: number;
    documentKind: number;
    file: File[];
  }) => Promise<void>;
  deleteDocuments: ({ documentsId }: { documentsId: string[] }) => Promise<void>;
  downloadDocuments: ({ documentsId }: { documentsId: string[] }) => Promise<void>;
  clear: () => void;
};

export type DocumentsStoreStoreWithTableStore<T> = DocumentsStoreType & TableStoreType<T>;

export class DocumentsStore
  extends TableStore<TableBodyDocuments | TableBodyDocumentsApplications>
  implements DocumentsStoreStoreWithTableStore<TableBodyDocuments | TableBodyDocumentsApplications>
{
  @observable
  public rootStore: IRootStore;

  @observable
  public selectedFilter?: IButtonGroupItem<string>;

  @observable
  public contragentId: string;

  @observable
  public modelId: string;

  @observable
  public sourceContext?: 'application' | 'contragent';

  @observable
  public documentsDropdownValue?: { label: string; value: string }[][] | undefined = undefined;

  @observable
  public documentsSelectValue?: { [key: string]: string }[] | undefined = undefined;

  @observable
  public isLoading: boolean = false;

  @observable
  public openRows: Set<string> = new Set();

  @observable
  public selectedRows: Set<string> = new Set();

  @observable
  public selectedDocumentsType?: string;

  @observable
  public selectedCreatedDateGte?: string;

  @observable
  public selectedCreatedDateLte?: string;

  @observable
  public selectedOrdering?: string;

  constructor(rootStore: IRootStore) {
    super();
    makeObservable(this);
    this.rootStore = rootStore;

    reaction(
      () => this.contragentId,
      async (value, previousValue) => {
        if (value !== previousValue && this.contragentId && this.modelId) {
          await this.getDocumentsById(this.contragentId, this.modelId);
        }
      },
    );

    reaction(
      () => this.selectedPage,
      (value, _previousValue) => {
        if (this.maxSelectedPage <= value) {
          this.getDocumentsById(this.contragentId, this.modelId);
        }
      },
    );

    reaction(
      () => this.selectedFilter?.type,
      (value, previousValue) => {
        this.handleChangePage(null, 1);
        if (previousValue !== value && this.selectedFilter) {
          this.openRows.clear();
          this.selectedRows.clear();
          this.selectedOrdering = '-document_type';
          this.getDocumentsById(this.contragentId, this.modelId);
        }
      },
    );

    reaction(
      () => this.searchString,
      (value, previousValue) => {
        if (previousValue !== value) {
          this.getDocumentsById(this.contragentId, this.modelId);
        }
      },
    );

    reaction(
      () => this.selectedDocumentsType,
      (value, previousValue) => {
        if (previousValue !== value && previousValue !== undefined) {
          this.getDocumentsById(this.contragentId, this.modelId);
        }
      },
    );

    reaction(
      () => this.selectedCreatedDateGte,
      (value, previousValue) => {
        if (previousValue !== value && previousValue !== undefined) {
          this.getDocumentsById(this.contragentId, this.modelId);
        }
      },
    );

    reaction(
      () => this.selectedCreatedDateLte,
      (value, previousValue) => {
        if (previousValue !== value && previousValue !== undefined) {
          this.getDocumentsById(this.contragentId, this.modelId);
        }
      },
    );

    reaction(
      () => this.selectedOrdering,
      (value, previousValue) => {
        if (previousValue !== value) {
          this.getDocumentsById(this.contragentId, this.modelId);
        }
      },
    );
  }

  @action
  getDocumentsById = async (id: string, modelId: string, immediately?: boolean) => {
    const isSearch = this.searchString || this.searchString?.trim();

    this.rootStore.requestTemplate({
      immediately,
      errorMessage: `Ошибка получения документов контрагента ${id}`,
      callback: async () => {
        const limit = isSearch ? 10 : this.rowsPerPageChange;
        const offset = this.rowsPerPageChange * this.selectedPage;
        const documentsType = this.selectedDocumentsType;
        const created__gte = this.selectedCreatedDateGte;
        const created__lte = this.selectedCreatedDateLte;
        const searchString = isSearch ? this.searchString?.trim() : undefined;
        const ordering = this.selectedOrdering ?? '-document_type';
        const filter =
          this.sourceContext === 'application' ? '5' : !this.selectedFilter?.type ? '1' : this.selectedFilter?.type;

        const { data } = await createAxiosRequest<
          {
            offset?: number;
            limit?: number;
            document_type?: string;
            name?: string;
            type_vid?: string;
            created__gte?: string;
            created__lte?: string;
            ordering?: string;
          },
          GetDocuments
        >({
          path: apiPath.documentsById.replace('{company_id}', `${id}`).replace('{model_id}', `${modelId}`),
          method: 'GET',
          params: {
            offset,
            limit,
            name: searchString,
            document_type: filter,
            type_vid: documentsType,
            created__gte,
            created__lte,
            ordering,
          },
          useToken: true,
        });
        runInAction(() => {
          this.limit = limit;
          this.summary = data.count;
          this.offset = offset;
          if (data.result.length > 0) {
            if ('document_id_vid' in data.result[0]) {
              const tableData = mapDocumentsToTableBody(data.result as TableBodyDocuments[]);
              this.tableBody = [...tableData];
            } else if ('id' in data.result[0]) {
              const tableData = mapDocumentsToTableBodyApp(data.result as TableBodyDocumentsApplications[]);
              this.tableBody = [...tableData];
            }
          } else {
            this.tableBody = [];
          }
        });
      },
    });
  };

  @action
  createDocuments = async ({
    id,
    modelId,
    userId,
    documentType,
    documentKind,
    file,
  }: {
    id: string;
    modelId: string;
    userId: number;
    documentType: number;
    documentKind: number;
    file: File[];
  }) => {
    const { setRequestSuccess } = this.rootStore;

    return this.rootStore.requestTemplate({
      successMessage: 'Данные успешно сохранены',
      errorMessage: 'Ошибка добавления документа',
      finallyAction: () => {
        const newFilter = {
          name: DOCUMENT_TYPE[documentType.toString() as keyof typeof DOCUMENT_TYPE],
          type: documentType.toString(),
        };

        if (this.selectedFilter?.type === newFilter.type) {
          this.getDocumentsById(id, this.modelId, true);
        } else {
          this.selectedFilter = newFilter;
        }

        if (
          this.selectedFilter.type === DOCUMENT_TYPE_SWOP['Документы по заявке'] &&
          this.sourceContext === 'contragent'
        ) {
          if (!this.openRows.has(modelId)) this.toggleRow(modelId);
          if (!this.openRows.has(`${modelId}_${documentKind.toString()}`))
            this.toggleRow(`${modelId}_${documentKind.toString()}`);
        } else {
          if (!this.openRows.has(documentKind.toString())) this.toggleRow(documentKind.toString());
        }
      },
      callback: async (successMessage?: string) => {
        await createAxiosRequest<{}, any>({
          path: apiPath.documentsById.replace('{company_id}', `${id}`).replace('{model_id}', `${modelId}`),
          method: 'POST',
          isFile: true,
          params: { user_id: userId, document_type: documentType, document_vid: documentKind },
          data: {
            files: file,
          },
          useToken: true,
        });

        setRequestSuccess(successMessage);
      },
    });
  };

  @action
  getDocumentsType = async () => {
    this.rootStore.requestTemplate({
      immediately: true,
      errorMessage: 'Ошибка получения видов документов',
      callback: async () => {
        if (!this.isLoading) {
          try {
            this.isLoading = true;
            const { data } = await createAxiosRequest<
              { offset?: number; limit?: number; status?: string; search?: string },
              GetDocumentsType
            >({
              path: apiPath.documentsType,
              method: 'GET',
              useToken: true,
            });
            runInAction(() => {
              if (data) {
                this.documentsDropdownValue = Object.values(data).map((group) => {
                  return Object.values(group).map((item, index) => ({
                    label: item,
                    value: `${index + 1}`,
                  }));
                });

                this.documentsSelectValue = Object.values(data).map((group) => {
                  const obj: { [key: string]: string } = {};
                  Object.values(group).forEach((value) => {
                    obj[value] = value;
                  });
                  return obj;
                });
              }
            });
          } catch (e) {
            throw e;
          } finally {
            this.isLoading = false;
          }
        }
      },
    });
  };

  @action
  deleteDocuments = async ({ documentsId }: { documentsId: string[] }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Не удалось удалить документы`,
      finallyAction: () => {
        this.getDocumentsById(this.contragentId, this.modelId, true);
        this.selectedRows.size && this.selectedRows.clear();
      },
      callback: async () => {
        await createAxiosRequest({
          path: apiPath.documentsType,
          method: 'DELETE',
          data: {
            file_id: documentsId,
          },
          useToken: true,
        });
      },
    });
  };

  @action
  downloadDocuments = async ({ documentsId }: { documentsId: string[] }) => {
    this.rootStore.requestTemplate({
      errorMessage: 'Ошибка загрузки документов',
      finallyAction: () => {
        this.selectedRows.size && this.selectedRows.clear();
      },
      callback: async () => {
        const paramsString = documentsId.map((id) => `file_id=${id}`).join('&');

        const response = await createAxiosRequest<{}, any>({
          path: `${apiPath.downloadDocuments}?${paramsString}`,
          method: 'GET',
          useToken: true,
          responseType: true,
        });

        const blob = new Blob([response.data], {
          type: response.headers['content-type'],
        });

        const encodedFilename = response.headers['x-filename'];

        const decodedFilename = encodedFilename?.startsWith('=?utf-8?b?')
          ? Buffer.from(encodedFilename.replace('=?utf-8?b?', '').trim(), 'base64').toString('utf-8')
          : encodedFilename;

        if (response.data) {
          const blobURL = window.URL.createObjectURL(blob);

          if (response.headers['content-type'] === 'application/pdf') {
            window.open(blobURL, '_blank');
          }

          const downloadLink = document.createElement('a');
          downloadLink.href = blobURL;
          downloadLink.download = decodedFilename || 'documents';
          downloadLink.click();

          setTimeout(() => {
            window.URL.revokeObjectURL(blobURL);
          }, 100);
        }
      },
    });
  };

  @action
  toggleRow = (rowId: string) => {
    if (this.openRows.has(rowId)) {
      this.openRows.delete(rowId);
    } else {
      this.openRows.add(rowId);
    }
  };

  @action
  toggleRowSelection = (rowId: string) => {
    if (this.selectedRows.has(rowId)) {
      this.selectedRows.delete(rowId);
    } else {
      this.selectedRows.add(rowId);
    }
  };

  @action
  setContragentId = (id: string) => (this.contragentId = id);

  @action
  setModelId = (modelId: string) => (this.modelId = modelId);

  @action
  setSourceContext = (sourceContext: 'application' | 'contragent') => (this.sourceContext = sourceContext);

  @action
  clear = () => {
    this.contragentId = '';
    this.modelId = '';
    this.tableBody = [];
    this.openRows.clear();
    this.selectedRows.clear();
    this.selectedFilter = undefined;
    this.searchString = undefined;
    this.sourceContext = undefined;
  };
}

const mapDocumentsToTableBody = (rawList: TableBodyDocuments[]): TableBodyDocuments[] => {
  return rawList?.map((item) => ({
    document_id_vid: item.document_id_vid,
    name_vid: item.name_vid,
    files: item.files,
  })) as TableBodyDocuments[];
};

const mapDocumentsToTableBodyApp = (rawList: TableBodyDocumentsApplications[]): TableBodyDocumentsApplications[] => {
  return rawList?.map((item) => ({
    id: item.id,
    count: item.count,
    result: item.result,
  })) as TableBodyDocumentsApplications[];
};
