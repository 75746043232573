import { styled, TextField, OutlinedInput, InputBase, alpha, SxProps, Theme, CSSObject } from '@mui/material';
import { color } from '@/constants/colors';

type CustomSxProps = {
  fontSize?: string | number;
  height?: string | number;
};

export const TextFieldStyled = styled(TextField)<{ sx?: CustomSxProps }>(({ theme, ...props }) => ({
  overflow: 'hidden',

  '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
    color: color.black,
  },

  '& .MuiButtonBase-root.MuiIconButton-root.Mui-disabled': {
    color: color.black,
  },

  '& .MuiFormControl-root .MuiTextField-root': {
    minWidth: '100%',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '2px',
    //@ts-ignore
    padding: props?.sx?.padding ?? '8px 10px',
    outline: 'none',
    fontSize: props?.sx?.fontSize ?? undefined,
    height: props?.sx?.height ?? undefined,
  },
  '&:active': {
    borderColor: color.white,
    outline: 'none',
  },
  '&:hover': {
    borderColor: color.white,
    outline: 'none',
  },
  '& .MuiOutlinedInput-input': {
    padding: 0,
    outline: 'none',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: color.gray,
  },
}));

export const TextFieldOutlinedStyled = styled(OutlinedInput)(({ theme, ...props }) => ({
  overflow: 'hidden',
  borderRadius: '2px',

  '& .MuiOutlinedInput-root': {
    borderRadius: '2px',
  },
  '& .MuiOutlinedInput-input.Mui-disabled': {
    color: color.black,
  },
}));

export const InputBaseOutlinedStyled = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== 'notched',
})(({ theme, readOnly, ...props }) => ({
  borderRadius: '4px',
  border: `1px solid ${color.light_green}`,
  height: props.disabled && readOnly ? '22px' : '36px',
  backgroundColor: 'white',

  '& .MuiInputBase-input': {
    padding: '0',
    paddingRight: '0 !important',
    '&.Mui-disabled': {
      WebkitTextFillColor: color.black,
      opacity: 1,
    },
  },

  '& #demo-multiple-checkbox .MuiSelect-select': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  '& #demo-multiple-checkbox.MuiSelect-select': {
    padding: '2px 26px 0 8px',
  },
}));

export const InputBaseOutlinedStyledStatus = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== 'notched',
})(({ theme, ...props }) => ({
  borderRadius: '0px',
  border: `1px solid ${color.gray}`,
  height: '36px',

  '& #demo-multiple-checkbox .MuiSelect-select': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  '& #demo-multiple-checkbox.MuiSelect-select': {
    padding: '2px 26px 0 8px',
  },

  '&.Mui-focused': {
    borderColor: color.light_green,
  },
}));

export const InputStyledMonitoring = styled(InputBase, {
  shouldForwardProp: (prop) => prop !== 'notched',
})(({ theme, ...props }) => ({
  '& .MuiInputBase-input': {
    borderRadius: 12,
    position: 'relative',
    border: '1px solid',
    borderColor: props.value ? color.emerald : color.light_gray,
    fontSize: 13,
    fontFamily: 'Raleway',
    fontWeight: '500',
    width: '100%',
    padding: '10px 0 10px 20px',

    '&:focus': {
      borderColor: color.emerald,
    },
  },
}));
