/* eslint-disable camelcase */
import { observable, makeObservable, action, reaction, computed, runInAction } from 'mobx';
import { apiPath } from '@/constants/api';
import type {
  ANALYTICS_STATUS,
  APP_ACC_ROLE,
  APP_FACT_TYPE,
  APP_STATUS_CUSTOM,
  LEGAL_ENTITY_TYPE,
} from '@/constants/backendEnum';
import { APP_STATUS_CUSTOM_TO_STATUS } from '@/constants/backendEnum';
import type { IButtonGroupItem } from '@/constants/types';
import { createAxiosRequest } from '@/utils/request';
import type { IGetApplications, IApplication, TableApplications } from '@/stores/ApplicationsStore';
import type { TableStoreType } from '@/stores/TableStore';
import { TableStore } from '@/stores/TableStore';
import type { IApplicationsPageDetailsStore, IRootStore } from '@/stores/type';
import { Owner } from './ContragentsStore';

export type IK62Details = {
  id: number;
  src_json: string;
  file_name: string;
  status: string;
  error_code: string;
  error_msg: string;
  file_period: string;
  client: string;
  cntrpty: number;
  app_acc: number;
  application: number;
  debtor_name: string;
};

export type IScoringDetail = {
  id: number;
  created: string;
  processed: string | null;
  status: keyof typeof ANALYTICS_STATUS;
  cntrprt_id: number;
  overall_score: number;
  overall_ml_score: number;
  error_msg: string | null;
  error_code: string | null;
  application: number;
};

export type IApplicationScoring = {
  id: number;
  created: string;
  processed: null;
  status: keyof typeof ANALYTICS_STATUS | null;
  cntrprt_id: number;
  overall_score: number;
  overall_ml_score: number;
  error_msg: string | null;
  error_code: number | null;
  application: number;
};

export type IApplicationAnalysis = {
  id: number;
  sys_crtd_tmstmp: string;
  src_json?: string | null;
  file_name: string;
  status: keyof typeof ANALYTICS_STATUS | null;
  error_code?: string | null;
  error_msg?: string | null;
  file_period: string;
  client: number;
  cntrpty: number;
  app_acc: number;
  application: number;
  name?: string;
  debtor_name?: string;
  file_path?: string;
};

export type IApplicationGuarantor = {
  id: number;
  created: string;
  updated: string;
  created_by: number;
  updated_by: number;
  role: keyof typeof APP_ACC_ROLE;
  legal_entity_type: keyof typeof LEGAL_ENTITY_TYPE;
  limit: number;
  deferment_days: number;
  collab_days: number;
  factoring_type: keyof typeof APP_FACT_TYPE;
  application: number;
  account: number;
  cntrprt: number;
  account_inn: string;
  account_name: string;
  account_risk_level: string;
  fullname?: string;
  account_problem_level?: string;
};

export type IApplicationCondition = {
  id: number;
  created?: string;
  updated?: string;
  created_by?: number;
  updated_by?: number;
  role: keyof typeof APP_ACC_ROLE;
  legal_entity_type: keyof typeof LEGAL_ENTITY_TYPE;
  limit?: number;
  deferment_days?: number;
  collab_days?: number;
  factoring_type: keyof typeof APP_FACT_TYPE;
  application?: number;
  account?: number;
  cntrprt?: number;
  account_inn: string;
  account_name: string;
  account_risk_level: string;
  account_problem_level: string;
  collab_months?: number;
  preferential_period?: number;
};

export interface ComplementaryActivities {
  code: string;
  date: string;
  text: string;
}

export type IApplicationContragent = {
  id: number;
  staff_fio: string;
  staff_role: string;
  name: string;
  primary_contact: string;
  risk_level_display: string;
  risk_level: string;
  created: string;
  updated: string;
  created_by: number;
  updated_by: number;
  inn: string;
  status: string;
  primary_phone: string;
  primary_email?: string;
  is_phys_legal_addr: boolean;
  cntrprt: number;
  principalactivity_name: string;
  principalactivity_code: string;
  full_name: string;
  head?: {
    fio?: string;
    innfl?: string;
    position?: string;
    date?: string;
    firstDate?: string;
    structuredFio?: {
      firstName?: string;
      lastName?: string;
      middleName?: string;
    };
  };
  problem_level: string;
  registrationdate?: string;
  industry?: string;
  complementary_activities?: ComplementaryActivities[] | null;
  activities?: string;
  segment?: string;
  company_group?: string;
  company_description?: string;
  address?: string;
  owners?: Owner[];
  site?: string;
  phones?: string;
  kpp?: string;
};

export type IApplicationDetail = {
  id: number;
  client_name: string;
  created: string;
  updated: string;
  created_by: number;
  updated_by: number;
  num: string;
  source: string;
  status: keyof typeof APP_STATUS_CUSTOM;
  stage: string;
  primary_contact: string | null;
  employees: [];
  staff_fio: string;
  staff_role: string;
  product_name?: string;
  client_manager?: string;
  type_application?: string;
  inn: string;
};

export type IResult<T> = {
  count: number;
  next?: string;
  previous?: string;
  results: T[];
};

const mapApplicationToTableBody = (rawList: IApplication[]): TableApplications[] => {
  return rawList?.map((item) => ({
    id: item?.id,
    number: item?.num,
    status: item?.status,
    stage: item?.stage,
    contact: item?.primary_contact ?? '',
    contragent: item?.client_name ?? 'None',
    productName: item?.product_name ?? '',
    created: item?.created ?? '',
    created_by: item?.created_by,
    typeApplication: item?.type_application ?? '',
    client_manager: item.client_manager ?? '-',
    inn: item.inn ?? '-',
    responsible_user: item.responsible_user ?? '-',
  })) as TableApplications[];
};

export type IApplicationsPageDetailsStoreStoreWithITableStore<T> = IApplicationsPageDetailsStore & TableStoreType<T>;

export class ApplicationsPageDetailsStore
  extends TableStore<TableApplications>
  implements IApplicationsPageDetailsStoreStoreWithITableStore<TableApplications>
{
  private possibleEditStatus = [
    APP_STATUS_CUSTOM_TO_STATUS['In Progress'],
    APP_STATUS_CUSTOM_TO_STATUS['On Refining'],
    APP_STATUS_CUSTOM_TO_STATUS['CREDIT_COMMITTEE'],
  ];

  @observable
  public rootStore: IRootStore;

  @observable
  public selectedFilter?: IButtonGroupItem<string>;

  @observable
  public selectedProduct?: string;

  @observable
  public selectedApplicationType?: string;

  @observable
  public selectedApplicationStatus?: string;

  @observable
  public selectedCreatedDateGte?: string;

  @observable
  public selectedCreatedDateLte?: string;

  @observable
  public selectedOderId?: string | number;

  @observable
  public detailApplicationById?: IApplicationDetail;

  @observable
  public contragentApplicationById?: IApplicationContragent;

  @observable
  public conditionApplicationById?: IApplicationCondition[];

  @observable
  public membersApplicationById?: IApplicationCondition[];

  @observable
  public guarantorsApplicationById?: IApplicationGuarantor[];

  @observable
  public analysisApplicationById?: IApplicationAnalysis[];

  @observable
  public scoringsApplicationById?: IApplicationScoring[];

  @observable
  public scoringByApplicationIdByScoringId: IScoringDetail;

  @observable
  public k62Details: IK62Details;

  @computed
  public get isPossibleEdit() {
    if (this?.detailApplicationById?.status) {
      return this.possibleEditStatus.includes(this?.detailApplicationById.status as any);
    }
    return false;
  }

  @observable isLoading: boolean = false;

  private isLoadingContragentInfo: boolean = false;

  constructor(rootStore: IRootStore) {
    super();
    makeObservable(this);
    this.rootStore = rootStore;

    reaction(
      () => this.selectedOderId,
      async (value, previousValue) => {
        if (value !== previousValue) {
          await this.getApplicationsById(this.selectedOderId);
        }
      },
    );

    reaction(
      () => this.selectedPage,
      async (value) => {
        if (this.maxSelectedPage <= value) {
          await this.getApplications();
        }
      },
    );

    reaction(
      () => this.rowsPerPageChange,
      async (value) => {
        if (this.maxSelectedPage <= value) {
          await this.getApplicationsById(this.selectedOderId);
        } else {
          await this.getApplications();
        }
      },
    );

    reaction(
      () => this.selectedFilter?.type,
      (value, previousValue) => {
        if (previousValue !== value) {
          this.handleChangePage(null, 0);
          this.getApplications();
        }
      },
    );

    reaction(
      () => this.searchString,
      (value, previousValue) => {
        if (previousValue !== value) {
          this.getApplications();
        }
      },
    );

    reaction(
      () => this.selectedProduct,
      (value, previousValue) => {
        if (previousValue !== value && previousValue !== undefined) {
          this.getApplications();
        }
      },
    );

    reaction(
      () => this.selectedApplicationType,
      (value, previousValue) => {
        if (previousValue !== value && previousValue !== undefined) {
          this.getApplications();
        }
      },
    );

    reaction(
      () => this.selectedApplicationStatus,
      (value, previousValue) => {
        if (previousValue !== value && previousValue !== undefined) {
          this.getApplications();
        }
      },
    );

    reaction(
      () => this.selectedCreatedDateGte,
      (value, previousValue) => {
        if (previousValue !== value && previousValue !== undefined) {
          this.getApplications();
        }
      },
    );

    reaction(
      () => this.selectedCreatedDateLte,
      (value, previousValue) => {
        if (previousValue !== value && previousValue !== undefined) {
          this.getApplications();
        }
      },
    );
  }

  @action
  setSelectedOderId = (id?: string | number) => (this.selectedOderId = id);

  @action
  getApplicationsById = async (id?: string | number) => {
    const { setIsLoading, setRequestError, isLoading } = this.rootStore;

    try {
      if (!isLoading) {
        setRequestError(undefined);
        setIsLoading(true);

        const limit = this.rowsPerPageChange;
        const offset = this.rowsPerPageChange * this.selectedPage;
        const { data } = await createAxiosRequest<{ offset: number; limit: number }, IGetApplications>({
          path: apiPath.applicationsById.replace('{account_pk}', `${id}`),
          method: 'GET',
          params: { offset: this.rowsPerPageChange * this.selectedPage, limit: this.rowsPerPageChange },
          useToken: true,
        });
        runInAction(() => {
          this.limit = limit;
          this.summary = data.count;
          this.offset = offset;
          const tableData = mapApplicationToTableBody(data.results);
          this.tableBody = [...tableData];
        });
      }
    } catch (e) {
      setRequestError('Ошибка получения данных: Заявки');
    } finally {
      setIsLoading(false);
    }
  };

  @action
  getApplications = async (immediately?: boolean) => {
    const isSearch = this.searchString || this.searchString?.trim();

    this.rootStore.requestTemplate({
      errorMessage: `Ошибка получения данных: Заявки`,
      immediately,
      callback: async () => {
        const productNames = this.selectedProduct;
        const applicationType = this.selectedApplicationType;
        const applicationStatus = this.selectedApplicationStatus;
        const created__gte = this.selectedCreatedDateGte;
        const created__lte = this.selectedCreatedDateLte;
        const searchString = isSearch ? this.searchString?.trim() : undefined;
        const limit = isSearch ? 10 : this.rowsPerPageChange;
        const offset = isSearch ? 0 : this.rowsPerPageChange * this.selectedPage;

        const { data } = await createAxiosRequest<
          {
            offset: number;
            limit: number;
            status?: string;
            search?: string;
            product_name__in?: string;
            type_application?: string;
            created__gte?: string;
            created__lte?: string;
          },
          IGetApplications
        >({
          path: apiPath.applications,
          method: 'GET',
          params: {
            offset,
            limit,
            status: applicationStatus,
            search: searchString,
            product_name__in: productNames,
            type_application: applicationType,
            created__gte,
            created__lte,
          },
          useToken: true,
        });
        runInAction(() => {
          this.limit = limit;
          this.summary = data.count;
          this.offset = offset;
          const tableData = mapApplicationToTableBody(data.results);
          this.tableBody = [...tableData];
        });
      },
    });
  };

  @action
  getDetailByApplicationId = async (id: string | number) => {
    if (!this.isLoading) {
      this.rootStore.requestTemplate({
        immediately: true,
        errorMessage: `Ошибка получения деталей заявки № ${id}`,
        callback: async () => {
          try {
            this.isLoading = true;
            const { data } = await createAxiosRequest<undefined, IApplicationDetail>({
              path: apiPath.detailApplicationsById.replace('{id}', `${id}`),
              method: 'GET',
              useToken: true,
            });
            runInAction(() => {
              this.detailApplicationById = data;
            });
          } finally {
            runInAction(() => {
              this.isLoading = false;
            });
          }
        },
      });
    }
  };

  @action
  updateStatusOrStageByApplicationId = async ({
    applicationId,
    status,
    stage,
    isPageContragentApplication,
  }: {
    applicationId: string | number;
    status?: string;
    stage?: string;
    isPageContragentApplication?: boolean;
  }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка обвновления ${status ? 'статуса' : 'состояния'} заявки ${applicationId}`,
      finallyAction: () => {
        const contragentId = this.rootStore.applicationsStore.contragenId;
        if (contragentId && isPageContragentApplication) {
          this.rootStore.applicationsStore.getApplicationsById(contragentId, true);
        }
      },
      callback: async () => {
        const { data } = await createAxiosRequest<any, IApplicationDetail>({
          path: apiPath.detailApplicationsById.replace('{id}', `${applicationId}`),
          method: 'PATCH',
          data: {
            status,
            stage,
            num: this.detailApplicationById?.num,
          },
          useToken: true,
        });
        runInAction(() => {
          this.detailApplicationById = { ...(this.detailApplicationById ?? {}), ...data };
        });
      },
    });
  };

  @action
  getContragentDetailsByApplicationId = async (id: string | number) => {
    if (!this.isLoadingContragentInfo) {
      this.rootStore.requestTemplate({
        errorMessage: `Ошибка получения деталей заявки №${id}`,
        immediately: true,
        callback: async () => {
          try {
            this.isLoadingContragentInfo = true;
            const { data } = await createAxiosRequest<undefined, IResult<IApplicationContragent>>({
              path: apiPath.contragentApplicationsById.replace('{application_pk}', `${id}`),
              method: 'GET',
              useToken: true,
            });
            runInAction(() => {
              this.contragentApplicationById = data?.results?.[0];
            });
          } finally {
            runInAction(() => {
              this.isLoadingContragentInfo = false;
            });
          }
        },
      });
    }
  };

  @action
  getConditionsByApplicationId = async ({ applicationId }: { applicationId: string | number }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка получения дебиторов заявки ${applicationId}`,
      callback: async () => {
        const { data } = await createAxiosRequest<{ limit: number; offset: number }, IResult<IApplicationCondition>>({
          path: apiPath.conditionApplicationsById.replace('{application_pk}', `${applicationId}`),
          method: 'GET',
          params: { limit: 500, offset: 0 },
          useToken: true,
        });
        runInAction(() => {
          this.conditionApplicationById = data?.results;
        });
      },
    });
  };

  /// Добавить дебитора
  @action
  createConditionsByApplicationId = async (
    applicationId: string,
    {
      limit,
      defermentDays,
      collabMonths,
      factoringType,
      inn,
      preferentialPeriod,
    }: {
      limit?: string | number;
      defermentDays?: string | number;
      collabMonths?: string | number;
      factoringType?: string | number;
      preferentialPeriod?: string | number;
      inn: string | number;
    },
  ) => {
    this.rootStore.requestTemplate({
      errorMessage: `Не удалось добавить дебитора`,
      finallyAction: () => {
        this.getConditionsByApplicationId({ applicationId });
        this.rootStore.contragentsStore.getAccounts(true);
      },
      callback: async () => {
        await createAxiosRequest({
          path: apiPath.conditionApplicationsById.replace('{application_pk}', `${applicationId}`),
          method: 'POST',
          data: {
            limit,
            deferment_days: defermentDays,
            collab_months: collabMonths,
            factoring_type: factoringType,
            preferential_period: preferentialPeriod,
            inn,
          },
          useToken: true,
        });
      },
    });
  };

  @action
  updateConditions = async ({
    url,
    paramsToPatch,
    applicationId,
  }: {
    url: string;
    paramsToPatch: any;
    applicationId: string | number;
  }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Не удалось обновить дебитора`,
      finallyAction: () => {
        this.getConditionsByApplicationId({ applicationId });
      },
      callback: async () => {
        await createAxiosRequest({
          path: url,
          method: 'PATCH',
          data: paramsToPatch,
          useToken: true,
        });
      },
    });
  };

  @action
  deleteCondition = async ({
    applicationId,
    debtorId,
  }: {
    applicationId: string | number;
    debtorId: string | number;
  }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Не удалось удалить дебитора`,
      finallyAction: () => {
        this.getConditionsByApplicationId({ applicationId });
      },
      callback: async () => {
        await createAxiosRequest({
          path: apiPath.conditionAcc.replace('{application_pk}', `${applicationId}`).replace('{id}', `${debtorId}`),
          method: 'DELETE',
          useToken: true,
        });
      },
    });
  };

  @action
  deleteConditionApp = async ({
    contragenId,
    applicationId,
  }: {
    contragenId?: string | number;
    applicationId: string | number;
  }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Не удалось удалить дебитора`,
      finallyAction: async () => {
        if (contragenId !== undefined) {
          await this.rootStore.applicationsStore.getApplicationsById(contragenId, true);
        }
        await this.getApplications();
      },
      callback: async () => {
        await createAxiosRequest({
          path: apiPath.conditionAccApp.replace('{id}', `${applicationId}`),
          method: 'DELETE',
          useToken: true,
        });
      },
    });
  };

  /// Получить участника (клиента)
  @action
  getMembersByApplicationId = async ({ applicationId }: { applicationId: string | number }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка получения участников заявки ${applicationId}`,
      callback: async () => {
        const { data } = await createAxiosRequest<{ limit: number; offset: number }, IResult<IApplicationCondition>>({
          path: apiPath.crMembersById.replace('{application_pk}', `${applicationId}`),
          method: 'GET',
          params: { limit: 500, offset: 0 },
          useToken: true,
        });
        runInAction(() => {
          this.membersApplicationById = data?.results;
        });
      },
    });
  };

  /// Добавить участника (клиента)
  @action
  createMembersByApplicationId = async (
    applicationId: string,
    {
      role,
      inn,
    }: {
      role: string;
      inn: string | number;
    },
  ) => {
    this.rootStore.requestTemplate({
      errorMessage: `Не удалось добавить компанию`,
      finallyAction: () => {
        this.getMembersByApplicationId({ applicationId });
      },
      callback: async () => {
        await createAxiosRequest({
          path: apiPath.crMembersById.replace('{application_pk}', `${applicationId}`),
          method: 'POST',
          data: {
            role,
            inn,
          },
          useToken: true,
        });
      },
    });
  };

  /// удалить участника (клиента)
  @action
  deleteMembersByApplicationId = async ({
    applicationId,
    memberId,
  }: {
    applicationId: string | number;
    memberId: string | number;
  }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Не удалось удалить компанию`,
      finallyAction: () => {
        this.getMembersByApplicationId({ applicationId });
      },
      callback: async () => {
        await createAxiosRequest({
          path: apiPath.crudMembersById.replace('{application_pk}', `${applicationId}`).replace('{id}', `${memberId}`),
          method: 'DELETE',
          useToken: true,
        });
      },
    });
  };

  @action
  getGuarantorsByApplicationId = async ({ applicationId }: { applicationId: string | number }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка получения поручителей заявки №${applicationId}`,
      callback: async () => {
        const { data } = await createAxiosRequest<undefined, IResult<IApplicationGuarantor>>({
          path: apiPath.guarnatorsApplicationsByById.replace('{application_pk}', `${applicationId}`),
          method: 'GET',
          useToken: true,
        });
        runInAction(() => {
          this.guarantorsApplicationById = data?.results;
        });
      },
    });
  };

  @action
  createGuarantorsByApplicationId = async ({
    applicationId,
    inn,
    fio,
  }: {
    applicationId: string | number;
    inn?: number | string;
    fio?: string;
  }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Не удалось добавить поручителя`,
      finallyAction: () => {
        this.getGuarantorsByApplicationId({ applicationId });
      },
      callback: async () => {
        const data = {
          inn,
          fullname: fio,
        };

        await createAxiosRequest({
          path: apiPath.guarnatorsApplicationsByById.replace('{application_pk}', `${applicationId}`),
          method: 'POST',
          data,
          useToken: true,
        });
      },
    });
  };

  @action
  deleteGuarantors = async ({ applicationId, id }: { applicationId: string | number; id: string | number }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Не удалось удалить поручителя`,
      finallyAction: () => {
        this.getGuarantorsByApplicationId({ applicationId });
      },
      callback: async () => {
        await createAxiosRequest({
          path: apiPath.editGuarantor.replace('{application_pk}', `${applicationId}`).replace('{id}', `${id}`),
          method: 'DELETE',
          useToken: true,
        });
      },
    });
  };

  @action
  getAnalysisByApplicationId = async ({ applicationId }: { applicationId: string | number }) => {
    if (!this.isLoading) {
      this.rootStore.requestTemplate({
        immediately: true,
        errorMessage: `Ошибка получения анализа К62 заявки №${applicationId}`,
        callback: async () => {
          this.isLoading = true;
          try {
            const { data: k62 } = await createAxiosRequest<undefined, IResult<IApplicationAnalysis>>({
              path: apiPath.analysisApplicationsByById.replace('{application_pk}', `${applicationId}`),
              method: 'GET',
              useToken: true,
            });

            const { data: k51 } = await createAxiosRequest<undefined, IResult<IApplicationAnalysis>>({
              path: apiPath.analysisApplicationsByByIdK51.replace('{application_pk}', `${applicationId}`),
              method: 'GET',
              useToken: true,
            });
            runInAction(() => {
              this.analysisApplicationById = [...k62?.results, ...k51?.results];
            });
          } finally {
            runInAction(() => {
              this.isLoading = false;
            });
          }
        },
      });
    }
  };

  @action
  createAnalysisByApplicationId = async ({
    applicationId,
    debtor,
    file,
    fileType,
  }: {
    applicationId: string | number;
    debtor: number | string | undefined | null;
    file: File;
    fileType: number;
  }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Не удалось добавить файл для анализа`,
      finallyAction: () => {
        this.getAnalysisByApplicationId({ applicationId });
      },
      callback: async () => {
        if (fileType === 62) {
          await createAxiosRequest({
            path: apiPath.analysisApplicationsByById.replace('{application_pk}', `${applicationId}`),
            method: 'POST',
            data: {
              debtor,
              k62file: file,
            },
            isFile: true,
            useToken: true,
          });
        } else if (fileType === 51) {
          await createAxiosRequest({
            path: apiPath.analysisApplicationsByByIdK51.replace('{application_pk}', `${applicationId}`),
            method: 'POST',
            data: {
              k51file: file,
            },
            isFile: true,
            useToken: true,
          });
        }
      },
    });
  };

  @action
  getScoringByApplicationId = async ({ applicationId }: { applicationId: string | number }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка получения скоринга заявки №${applicationId}`,
      callback: async () => {
        const { data } = await createAxiosRequest<{ limit: number; offset: 0 }, IResult<IApplicationScoring>>({
          path: apiPath.scoringsApplicationsByById.replace('{application_pk}', `${applicationId}`),
          method: 'GET',
          data: { offset: 0, limit: 100 },
          useToken: true,
        });
        runInAction(() => {
          this.scoringsApplicationById = data?.results;
        });
      },
    });
  };

  @action
  createScoringByApplicationId = async ({ applicationId }: { applicationId: string | number }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка получения скоринга заявки №${applicationId}`,
      finallyAction: () => {
        this.getScoringByApplicationId({ applicationId });
      },
      callback: async () => {
        await createAxiosRequest<undefined, IResult<IApplicationScoring>>({
          path: apiPath.scoringsApplicationsByById.replace('{application_pk}', `${applicationId}`),
          method: 'POST',
          useToken: true,
        });
      },
    });
  };

  @action
  getScoringByApplicationIdByScoringId = async ({
    applicationId,
    scoringId,
  }: {
    applicationId: string | number;
    scoringId: string | number;
  }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка получения скоринга заявки №${applicationId}`,
      callback: async () => {
        const { data } = await createAxiosRequest<undefined, IScoringDetail>({
          path: apiPath.scoringByApplicationIdByScoringId
            .replace('{application_pk}', `${applicationId}`)
            .replace('{id}', `${scoringId}`),
          method: 'GET',
          useToken: true,
        });
        runInAction(() => {
          this.scoringByApplicationIdByScoringId = data;
        });
      },
      finallyAction: () => {
        if (!this.detailApplicationById) {
          this.getDetailByApplicationId(applicationId);
        }
      },
    });
  };

  @action
  getK62Details = async ({
    applicationId,
    k62Id,
    fileType,
  }: {
    applicationId: string | number;
    k62Id: string | number;
    fileType: string;
  }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка получения деталей файла К62`,
      immediately: true,
      callback: async () => {
        if (fileType === '62') {
          const { data } = await createAxiosRequest<undefined, IK62Details>({
            path: apiPath.k62Details.replace('{application_pk}', `${applicationId}`).replace('{id}', `${k62Id}`),
            method: 'GET',
            useToken: true,
          });

          this.k62Details = data;
        } else if (fileType === '51') {
          const { data } = await createAxiosRequest<undefined, IK62Details>({
            path: apiPath.k51Details.replace('{application_pk}', `${applicationId}`).replace('{id}', `${k62Id}`),
            method: 'GET',
            useToken: true,
          });
          runInAction(() => {
            this.k62Details = data;
          });
        }
      },
      finallyAction: () => {
        if (!this.detailApplicationById) {
          this.getDetailByApplicationId(applicationId);
        }
      },
    });
  };

  @action
  k62Delete = async ({
    applicationId,
    k62Id,
    fileType,
  }: {
    applicationId: string | number;
    k62Id: string | number;
    fileType: number;
  }) => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка удаления деталей файла К${fileType}`,
      immediately: true,
      callback: async () => {
        if (fileType === 62) {
          await createAxiosRequest<undefined, IK62Details>({
            path: apiPath.k62Details.replace('{application_pk}', `${applicationId}`).replace('{id}', `${k62Id}`),
            method: 'DELETE',
            useToken: true,
          });
        } else if (fileType === 51) {
          await createAxiosRequest<undefined, IK62Details>({
            path: apiPath.k51Details.replace('{application_pk}', `${applicationId}`).replace('{id}', `${k62Id}`),
            method: 'DELETE',
            useToken: true,
          });
        }
      },

      finallyAction: () => {
        this.getAnalysisByApplicationId({ applicationId });
      },
    });
  };

  @action
  downloadAnalytics = async ({ cardId, type }: { cardId: string; type: string }) => {
    if (!this.isLoading) {
      this.rootStore.requestTemplate({
        errorMessage: 'Ошибка выгрузки аналитики',
        callback: async () => {
          this.isLoading = true;

          try {
            const response = await createAxiosRequest<{}, any>({
              path: apiPath.analyticsDownload.replace('{id}', `${cardId}`).replace('{type}', `${type}`),
              method: 'GET',
              useToken: true,
              responseType: true,
            });

            const blob = new Blob([response.data], {
              type: response.headers['content-type'],
            });

            const encodedFilename = response.headers['x-filename'];

            let decodedFilename: string;

            if (encodedFilename && encodedFilename.startsWith('=?utf-8?b?')) {
              const base64String = encodedFilename.replace('=?utf-8?b?', '').trim();
              decodedFilename = Buffer.from(base64String, 'base64').toString('utf-8');
            } else {
              decodedFilename = encodedFilename;
            }

            if (response.data) {
              const downloadLink = document.createElement('a');
              downloadLink.href = window.URL.createObjectURL(blob);
              downloadLink.download = decodedFilename || 'analytics';
              downloadLink.click();
            }
          } finally {
            this.isLoading = false;
          }
        },
      });
    }
  };
}
