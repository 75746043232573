import { action, makeObservable, observable, reaction, runInAction } from 'mobx';
import { TableStoreType, TableStore } from '@/stores/TableStore';
import type { IRootStore } from '@/stores/type';
import { createAxiosRequest } from '@/utils/request';
import { apiPath } from '@/constants/api';

export type IAccountsDebtorsLimitsStore = {
  getDebtorsLimits: (id: string | number) => void;
  getAsDebtorsLimits: (id: string | number) => void;
  setContragenId: (id?: string | number) => void;
  contragentId?: string | number;
  limitAsDebtor?: ILimitAsDebtor[];
};

export type ILimitAsDebtor = {
  id: number;
  parent_name: string;
  parent_inn: string;
  parent_account: {
    id: number;
    inn: string;
    name: string;
  };
  debtor: {
    id: number;
    head: string;
    primary_contact: string;
    risk_level_display: string;
    risk_level: string;
    account_extension: {
      id: number;
      limit: number;
      amountpp: number;
      amountdt: number;
      provision: string;
      product: string;
      scheme: string;
      percent: number;
      account: number;
    };
    created_by: {
      pk: number;
      username: string;
      last_name: string;
      first_name: string;
      middle_name: string;
      email: string;
      url: string;
      role: string;
      phone: string;
    };
    created: string;
    updated: string;
    updated_by: number;
    inn: string;
    status: string;
    primary_phone: string;
    primary_email: string;
    is_phys_legal_addr: boolean;
    name: string;
    site: string;
    phones: string;
    industry: string;
    segment: string;
    cntrprt: number;
    company_group: number;
  };
  debtor_extension: {
    id: number;
    inn: string;
    limit: number;
    amountpp: number;
    amountdt: number;
    product: string;
    percent: number;
    decisionnumber: string;
    decisiondate: string;
    limitupdatedate: string;
    postponement: number;
    regressionperiod: number;
    cf: number;
    account_relation: number;
  };
  inn: string;
  account: number;
};

export type ILimitDebtor = {
  id: number;
  debtor: {
    id: number;
    head: {
      fio: string;
      innfl: string;
      position: string;
      date: string;
      firstDate: string;
      structuredFio: {
        firstName: string;
        lastName: string;
        middleName: string;
      };
    };
    primary_contact: string | null;
    risk_level_display: string | null;
    risk_level: string | null;
    created: string;
    updated: string;
    created_by: number;
    updated_by: number;
    inn: string;
    status: string;
    primary_phone: string;
    primary_email: null;
    is_phys_legal_addr: boolean;
    name: string;
    is_active: boolean;
    site: string;
    phones: string;
    cntrprt: number;
    company_group: string | null;
  };
  debtor_extension: {
    id: number;
    inn: string;
    limit: number;
    amountpp: number;
    amountdt: number;
    product: string;
    percent: number;
    decisionnumber: string;
    decisiondate: string;
    limitupdatedate: string;
    postponement: number;
    regressionperiod: string;
    cf: number;
    account_relation: number;
  };
  inn: string;
  account: number;
  parent_account: {
    id: number;
    inn: string;
    name: string;
  };
};

export type IGetLimitDebtor = {
  count: number;
  next?: string;
  previous?: string;
  results: ILimitDebtor[];
};

export type IGetLimitAsDebtor = {
  count: number;
  next?: string;
  previous?: string;
  results: ILimitAsDebtor[];
};

export type ITableBodyDebtorsLimits = {
  id: number;
  name: string;
  contractNumber: string;
  inn: string;
  limit: number;
  loadedLimitPercent: number;
  loadedLimitAmount: number;
  freeLimit: number;
  fundingRatio: number;
  postponement: number;
  regression: string | number;
  product?: string;
  debtorId?: number;
  supplierId?: number;
  parentAccountId?: number;
};

export type AccountsDebtorsLimitsStoreWithTableStore<T> = IAccountsDebtorsLimitsStore & TableStoreType<T>;

export class AccountsDebtorsLimitsStore
  extends TableStore<ITableBodyDebtorsLimits>
  implements AccountsDebtorsLimitsStoreWithTableStore<ITableBodyDebtorsLimits>
{
  @observable
  public rootStore: IRootStore;

  @observable
  public contragenId?: string | number;

  @observable
  public limitAsDebtor?: ILimitAsDebtor[];

  @action
  setContragenId = (id?: string | number) => (this.contragenId = id);

  constructor(rootStore: IRootStore) {
    super();
    makeObservable(this);
    this.rootStore = rootStore;

    reaction(
      () => this.searchString,
      (value, previousValue) => {
        if (previousValue !== value && this.contragenId) {
          this.getDebtorsLimits(this.contragenId);
        }
      },
    );

    reaction(
      () => this.contragenId,
      async (value, previousValue) => {
        if (value !== previousValue && this.contragenId) {
          await this.getDebtorsLimits(this.contragenId);
          await this.getAsDebtorsLimits(this.contragenId);
        }
      },
    );

    reaction(
      () => this.selectedPage,
      (value) => {
        if (this.maxSelectedPage <= value && this.contragenId) {
          this.getDebtorsLimits(this.contragenId);
        }
      },
    );
  }

  @action
  getDebtorsLimits = (id: string | number) => {
    this.rootStore.requestTemplate({
      immediately: true,
      errorMessage: `Ошибка получения данных дебиторов`,
      callback: async () => {
        const isSearch = this.searchString || this.searchString?.trim();

        const limit = 100;
        const offset = isSearch ? 0 : this.rowsPerPageChange * this.selectedPage;

        const searchString = isSearch ? this.searchString?.trim() : undefined;

        const { data } = await createAxiosRequest<
          { name?: string; search?: string; limit?: number; offset?: number },
          IGetLimitDebtor
        >({
          path: `${apiPath.onecDebtorsLimits}`.replace('{account_pk}', `${id}`),
          method: 'GET',
          useToken: true,
          params: { search: searchString, limit, offset },
        });
        runInAction(() => {
          this.limit = limit;
          this.summary = data.count;
          this.offset = offset;
          this.tableBody = mapAccountToTableBody(data.results);
        });
      },
    });
  };

  @action
  getAsDebtorsLimits = (id: string | number) => {
    this.rootStore.requestTemplate({
      errorMessage: `Ошибка получения данных дебиторов`,
      immediately: true,
      callback: async () => {
        const { data } = await createAxiosRequest<
          { name?: string; search?: string; limit?: number; offset?: number },
          IGetLimitAsDebtor
        >({
          path: `${apiPath.onecAsDebtorsLimits}`.replace('{account_pk}', `${id}`),
          method: 'GET',
          useToken: true,
          params: { limit: 100, offset: 0 },
        });
        runInAction(() => {
          this.limitAsDebtor = data.results;
        });
      },
    });
  };
}

const mapAccountToTableBody = (rawList: ILimitDebtor[]): ITableBodyDebtorsLimits[] => {
  return rawList?.map((item) => ({
    id: item.id,
    name: item.debtor.name,
    contractNumber: `item.`,
    inn: item.debtor.inn,
    limit: item?.debtor_extension?.limit,
    loadedLimitPercent: item?.debtor_extension?.percent,
    loadedLimitAmount: item?.debtor_extension?.amountdt,
    freeLimit: item?.debtor_extension?.amountpp,
    fundingRatio: item?.debtor_extension?.cf,
    postponement: item?.debtor_extension?.postponement,
    regression: item?.debtor_extension?.regressionperiod,
    product: item?.debtor_extension?.product,
    supplierId: item?.account,
    parentAccountId: item?.parent_account.id,
  })) as ITableBodyDebtorsLimits[];
};
