import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import styled from '@emotion/styled';
import { PaginationItem } from '@mui/material';

import { OFFSET } from '@/constants/sizes';
import { color } from '@/constants/colors';
import { statusMonitoringColorMap, statusSignalLabelColorMap } from '@/constants/backendEnum';

interface TableCellStyledProps {
  verticalAlign?: string;
  textAlign?: 'left' | 'right' | 'center' | 'justify' | 'initial' | 'inherit';
}

export const TableCellStyled = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'textAlign' && prop !== 'verticalAlign',
})<TableCellStyledProps>(({ verticalAlign, textAlign }) => ({
  border: 0,
  color: color.inherit,
  verticalAlign: verticalAlign ?? 'baseline',
  textAlign: textAlign ?? 'left',
}));

export const TableCellStyledMonitoring = styled(TableCell)<{
  verticalAlign?: string;
  textAlign?: string;
  statusSignal?: string;
}>(({ verticalAlign, statusSignal }) => ({
  border: 0,
  fontWeight: '500',
  padding: '8px',
  height: `47px`,
  color: statusSignalLabelColorMap[statusSignal || ''] || color.black,
  verticalAlign: verticalAlign ?? 'baseline',
}));

export const TableCellStyledWithoutPadding = styled(TableCell)<{ verticalAlign?: string }>(
  ({ theme, verticalAlign }) => ({
    border: 0,
    color: color.inherit,
    verticalAlign: verticalAlign ?? 'baseline',
    padding: 0,
    paddingTop: '7px',
  }),
);

export const TableRowStyled = styled(TableRow)(({ theme }) => ({
  '&:hover>td': {
    backgroundColor: color.pale_green,
    color: color.royal_blue,
    textDecorationLine: 'underline',
    cursor: 'pointer',
  },
}));

export const TableRowStyledMonitoring = styled(TableRow)<{
  statusSignal?: string;
}>(({ statusSignal }) => ({
  backgroundColor: statusMonitoringColorMap[statusSignal || ''] || '',

  '&:hover': {
    backgroundColor: color.pale_green,
  },
}));

export const TableRowStyledWithoutPadding = styled(TableRow)(({ theme }) => ({
  padding: 0,
}));

export const PaginationItemStyled = styled(PaginationItem)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: color.honeydew,
    border: `1px solid ${color.moderately_green}`,
  },
}));

export const TableCellSmallPadding = styled(TableCellStyled)<TableCellProps & { rightPadding?: string }>(
  ({ theme, rightPadding }) => ({
    padding: rightPadding
      ? `${OFFSET.xxxs} ${OFFSET.xs} ${rightPadding} ${OFFSET.none}`
      : `${OFFSET.xxxs} ${OFFSET.xs}`,
    verticalAlign: 'middle',
  }),
);

export const TableCellSmallHeight = styled(TableCellStyled)(({ theme }) => ({
  height: `37px`,
  verticalAlign: 'middle',
}));

export const TableCellStyledDocuments = styled(TableCell, {
  shouldForwardProp: (prop) => prop !== 'textAlign' && prop !== 'verticalAlign',
})<TableCellStyledProps & { fontSize?: string; fontWeight?: string }>(
  ({ theme, verticalAlign, fontSize, fontWeight, textAlign }) => ({
    border: 0,
    color: color.inherit,
    verticalAlign: verticalAlign ?? 'baseline',
    textAlign: textAlign ?? 'left',
    fontWeight: fontWeight || 500,
    fontSize: fontSize || '16px',
    height: '30px',
    padding: '10px 8px',
  }),
);
