import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import Box from '@mui/material/Box';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from '@mui/material';

import { OFFSET } from '@/constants/sizes';
import { useRootStore } from '@/stores';
import { MoreMenu } from '@/components/ApplicationsPage/MoreMenu';
import type { TabBarItem } from '@/components/common/TabBarStyled';
import { TabBarStyled } from '@/components/common/TabBarStyled';
import { ApplicationClients } from '@/components/ApplicationsPage/ApplicationsTabs/ApplicationClients';
import { LabelLikeHintWithText, typographyStyleBody } from '@/components/common/LabelLikeHintWithText';
import { ApplicationAddCondition } from '@/components/ApplicationsPage/ApplicationsTabs/ApplicationAddTable/ApplicationAddCondition';
import { Analysis } from '@/components/ApplicationsPage/ApplicationsTabs/Analysis';
import { APP_STATUS_CUSTOM, PROBLEM_LEVEL } from '@/constants/backendEnum';
import type { BadgeType } from '@/components/atoms/Bage/Badge';
import { Badge } from '@/components/atoms/Bage/Badge';
import { Breadrcrumbs } from '@/components/common/Breadrcrumbs';
import { Assessment } from '@/components/Administering/Assessment/Assessment';
import { RETURN_TAB_VALUES, ROLE, ROUTES } from '@/constants';
import { color } from '@/constants/colors';
import { ApplicationAddConditionGuarantors } from '@/components/ApplicationsPage/ApplicationsTabs/ApplicationAddTableGuarantors/ApplicationAddConditionGuarantors';
import { SelectStyled } from '@/components/atoms/Select/SelectStyled';
import { ModalDelete } from '@/components/common/ModalDelete';
import { useModalDelete } from '@/components/common/hooks/useModalDelete';
import { TableApplications } from '@/stores/ApplicationsStore';
import { ContragentDocuments } from '@/components/Contagents/ContactTabs/ContragentDocuments';

export const APP_STAGE_TO_ACTIVE_STEP = {
  Refinment: 0,
  'Express Risk Assessment': 1,
  'Committee Assessment': 2,
  Contracting: 3,
} as const;

export const ACTIVE_STEP_TO_APP_STAGE: { [k: number]: string } = {
  0: 'Refinment',
  1: 'Express Risk Assessment',
  2: 'Committee Assessment',
  3: 'Contracting',
} as const;

export const RETURN_TABS: { [k: number]: string } = {
  0: 'Дебиторы',
  1: 'Поручители',
  2: 'Анализ файлов',
  3: 'Клиент',
} as const;

const tabBarItems: TabBarItem[] = [
  {
    name: 'Клиент',
    component: () => <ApplicationClients />,
    permissions: [ROLE.SMARTFACT, ROLE.GENERAL, ROLE.BALTICLEASING, ROLE.PSBL],
  },
  {
    name: 'Дебиторы',
    component: () => <ApplicationAddCondition />,
    permissions: [ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL],
  },
  {
    name: 'Поручители',
    component: () => <ApplicationAddConditionGuarantors />,
    permissions: [ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL],
  },
  {
    name: 'Анализ файлов',
    component: () => <Analysis />,
    permissions: [ROLE.SMARTFACT, ROLE.GENERAL, ROLE.BALTICLEASING, ROLE.PSBL],
  },
  {
    name: 'Оценка',
    component: () => <Assessment />,
    permissions: [ROLE.SMARTFACT, ROLE.GENERAL, ROLE.BALTICLEASING, ROLE.PSBL],
  },
  {
    name: 'Документы по заявке',
    component: () => <ContragentDocuments sourceContext={'application'} />,
    permissions: [ROLE.SMARTFACT, ROLE.BALTICLEASING, ROLE.PSBL],
  },
];

const selectTitle = (
  <>
    Выберите зону <br /> проблемности
  </>
);

export const ApplicationPageDetails = observer(() => {
  const { applicationsPageDetailsStore, userStore, documentsStore } = useRootStore();
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [renderComponent, setRenderComponent] = useState(tabBarItems[0].component);
  const [selectedTab, setSelectedTab] = useState(tabBarItems[0]);
  const [activeStep, setActiveStep] = useState(0);
  const { open, handleClose, handleOpen } = useModalDelete<TableApplications>();

  const locationState = location.state;

  useEffect(() => {
    if (locationState && typeof locationState === 'string') {
      const findItem = tabBarItems.find((item) => item.name === locationState) ?? tabBarItems[0];
      onChangeTab(findItem.name);
      setSelectedTab(findItem);
    }
  }, [locationState]);

  const menuChangeStatus = (status: string) => {
    applicationsPageDetailsStore
      .updateStatusOrStageByApplicationId({ applicationId: id as string, status })
      .then(() => applicationsPageDetailsStore.getApplications(true));
  };

  const onChangeTab = (currentTab: string) => {
    setRenderComponent(tabBarItems.find(({ name }) => name === currentTab)?.component ?? <></>);
  };

  const menuItemOnClick = (needSelectTab: string) => {
    const findItem = tabBarItems.find(({ name }) => name === needSelectTab) ?? tabBarItems[1];
    onChangeTab(findItem.name);
    setSelectedTab(findItem);
  };

  const menuItemOnChangeStepper = () => {
    const nextStage = ACTIVE_STEP_TO_APP_STAGE[activeStep + 1];
    if (nextStage) {
      applicationsPageDetailsStore
        .updateStatusOrStageByApplicationId({ applicationId: id as string, stage: nextStage })
        .then(() => applicationsPageDetailsStore.getApplications(true));
    }
  };

  useEffect(() => {
    if (id) {
      applicationsPageDetailsStore.getContragentDetailsByApplicationId(id);
      applicationsPageDetailsStore.getDetailByApplicationId(id);
    }
  }, [id]);

  const {
    client_name,
    inn,
    num,
    status: applicationStatus,
    stage,
    source,
    product_name,
    client_manager,
    type_application,
  } = applicationsPageDetailsStore.detailApplicationById || {};

  const { problem_level, id: counterpartyId } = applicationsPageDetailsStore.contragentApplicationById || {};

  useEffect(() => {
    if (stage) {
      setActiveStep(APP_STAGE_TO_ACTIVE_STEP[stage as keyof typeof APP_STAGE_TO_ACTIVE_STEP] ?? 0);
    }
  }, [stage]);

  const onArrowClick = () => {
    navigate(`/contragents/${locationState?.contragenId}`, { state: { route: locationState?.route } });
  };

  const handleDelete = () => {
    if (id) {
      applicationsPageDetailsStore.deleteConditionApp({ applicationId: id });
    }
    handleClose();
    navigate(`/applications`);
  };

  const handleNavigateToClientDetail = () => {
    if (counterpartyId) {
      navigate(`${ROUTES.contragents}/${counterpartyId}`, { state: { route: RETURN_TABS[3], applicationId: id } });
    }
  };

  useEffect(() => {
    return () => {
      documentsStore.clear();
    };
  }, []);

  return (
    <>
      <Box mb={OFFSET.xxxl}>
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            alignItems: 'center',
            marginTop: OFFSET.sm,
            mb: OFFSET.sm,
            justifyContent: 'space-between',
          }}
        >
          <Breadrcrumbs
            breadCrumbItems={[
              {
                name: num ?? '-',
              },
            ]}
          />
        </Box>
        <Box
          sx={{
            height: '88px',
            mrgingTop: '24px',
            background: color.light,
            bapplication: `1px solid ${color.honeydew}`,
            bapplicationRadius: '4px',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            display: 'flex',
            padding: '0 24px',
          }}
        >
          <LabelLikeHintWithText title="Клиент/ИНН">
            <Link
              onClick={handleNavigateToClientDetail}
              variant="h6"
              gutterBottom
              underline="none"
              sx={{
                ...typographyStyleBody,
                '&:hover': {
                  textDecoration: 'underline',
                  cursor: 'pointer',
                },
              }}
            >
              {`${client_name} / ${inn}`}
            </Link>
          </LabelLikeHintWithText>
          <LabelLikeHintWithText title="Статус">
            <Badge type={applicationStatus as keyof typeof BadgeType}>
              {APP_STATUS_CUSTOM[applicationStatus as keyof typeof APP_STATUS_CUSTOM]}
            </Badge>
          </LabelLikeHintWithText>
          <LabelLikeHintWithText title="Продукт" value={product_name ?? '-'} />
          <LabelLikeHintWithText title="Тип заявки" value={type_application ?? '-'} />
          <LabelLikeHintWithText title="Клиентский менеджер" value={client_manager ?? '-'} />
          <LabelLikeHintWithText title="Источник заявки" value={source ?? '-'} />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <LabelLikeHintWithText title={'Зона проблемности'} />
            {problem_level && (
              <SelectStyled
                defValue={PROBLEM_LEVEL[problem_level as keyof typeof PROBLEM_LEVEL]}
                items={PROBLEM_LEVEL}
                title={selectTitle}
                disabled
              />
            )}
          </Box>
          <MoreMenu
            menuItemOnClick={menuItemOnClick}
            menuItemOnChangeStepper={menuItemOnChangeStepper}
            currentStatus={applicationStatus}
            menuChangeStatus={menuChangeStatus}
            handleOpenDeleteModal={handleOpen}
          />
        </Box>
        <Box
          sx={{
            mt: OFFSET.sm,
            mb: OFFSET.sm,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <TabBarStyled
            items={tabBarItems}
            selected={selectedTab}
            onChangeTab={onChangeTab}
            userRole={userStore.profileInfo?.view_set}
          />
        </Box>
        {locationState && locationState.route === RETURN_TAB_VALUES.APPLICATION && (
          <ArrowBackIcon
            onClick={onArrowClick}
            sx={{
              color: color.light_green,
              marginBottom: '19px',
              cursor: 'pointer',
            }}
          />
        )}
        {renderComponent}
      </Box>
      <ModalDelete
        uniqId="Data_table_modal"
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
        title={`Вы действительно хотите удалить заявку №${num}?`}
        descriptions={`Статус заявки: "${APP_STATUS_CUSTOM[applicationStatus as keyof typeof APP_STATUS_CUSTOM]}"`}
      />
    </>
  );
});
