import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { Box, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Collapse from '@mui/material/Collapse';

import { TableTemplateDocuments } from '@/components/Templates/TableTemplate';
import { useRootStore } from '@/stores';
import {
  TableRowStyled,
  TableCellStyled,
  TableCellStyledDocuments,
  TableRowStyledWithoutPadding,
} from '@/components/Templates/TableComponentStyled';
import { useModalDelete } from '@/components/common/hooks/useModalDelete';
import { ModalDelete } from '@/components/common/ModalDelete';
import { color } from '@/constants/colors';
import { dateFormatterDefault, pluralize } from '@/utils';
import { CheckboxStyled } from '@/components/atoms/Checkbox/CheckboxStyled';
import { MoreMenuTabData } from '@/components/Administering/AdministrationTabData/MoreMenuTabData';
import { DocumentsFile, TableBodyDocuments, TableBodyDocumentsApplications } from '@/stores/DocumentsStore';

const tableHeader = ['Вид документа', 'Кем загружено', 'Дата загрузки', ''];
const tableHeaderWidth = ['64.8%', '22.6%', '9.8%', '2.8%'];

const getModalText = (document?: TableBodyDocuments | DocumentsFile | string[]) => {
  if (!document) {
    return {
      title: 'Информация о документе отсутствует',
      description: ' ',
    };
  }

  if (Array.isArray(document)) {
    const count = document.length;
    const countText = pluralize({
      count,
      words: ['файл', 'файла', 'файлов'],
    });

    return {
      title: `Вы действительно хотите удалить все выделенные документы (${countText})?`,
      description: ` `,
    };
  }

  if ('name_vid' in document) {
    const countText = pluralize({
      count: document.files.length,
      words: ['документ', 'документа', 'документов'],
    });

    return {
      title: `Вы действительно хотите удалить все документы вида (${countText})?`,
      description: `${document.name_vid}?`,
    };
  }

  return {
    title: 'Вы действительно хотите удалить документ:',
    description: `${document.file_name}?`,
  };
};

const RowTableBodyDocuments = observer(
  ({
    row,
    filled,
    handleRowClick,
    handleDeleteRow,
    handleCheckboxChange,
    downloadDocumentById,
  }: {
    row: TableBodyDocuments;
    filled: boolean;
    handleRowClick: () => void;
    handleDeleteRow: (row?: DocumentsFile | TableBodyDocuments) => void;
    handleCheckboxChange: (id: string) => void;
    downloadDocumentById: (row?: TableBodyDocuments, documentId?: string) => Promise<void>;
  }) => {
    const { documentsStore } = useRootStore();

    const isRowOpen = documentsStore.openRows?.has(row.document_id_vid.toString());

    return (
      <>
        <TableRowStyled
          key={row.document_id_vid}
          sx={[
            {
              backgroundColor: filled ? color.light : color.transparent,
              borderBottom: isRowOpen ? `1px solid ${color.light_gray_v2}` : undefined,
            },
          ]}
          onClick={() => handleRowClick()}
        >
          <TableCellStyledDocuments verticalAlign={'middle'}>
            <Box display={'flex'} alignItems={'center'}>
              <Box display={'flex'} alignItems={'center'} marginLeft={'-6px'} marginRight={'4px'}>
                {isRowOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
              </Box>
              {row.name_vid}
              {row.files?.length && (
                <Typography
                  sx={{
                    marginLeft: '5px',
                    fontWeight: 500,
                    fontSize: '16px',
                    lineHeight: '18.78px',
                    color: color.grayMain,
                  }}
                >
                  ({row.files?.length})
                </Typography>
              )}
            </Box>
          </TableCellStyledDocuments>
          <TableCellStyledDocuments verticalAlign={'middle'}></TableCellStyledDocuments>
          <TableCellStyledDocuments verticalAlign={'middle'}></TableCellStyledDocuments>
          <TableCellStyledDocuments align="right" verticalAlign={'middle'}>
            <MoreMenuTabData
              onDelete={() => handleDeleteRow(row)}
              toDownloadById={true}
              handleDownload={() => downloadDocumentById(row)}
              padding={'4px'}
            />
          </TableCellStyledDocuments>
        </TableRowStyled>

        {row.files?.map(({ file_id, file_date, file_name, user_id }) => (
          <TableRowStyled key={file_id}>
            <TableCellStyled sx={{ padding: 0 }} colSpan={tableHeader.length}>
              <Collapse in={isRowOpen} timeout={600} unmountOnExit>
                <TableRowStyled
                  sx={{ borderBottom: `1px solid ${color.light_gray_v2}`, width: '100%', display: 'inline-table' }}
                >
                  <TableCellStyledDocuments
                    verticalAlign={'middle'}
                    sx={{ minWidth: tableHeaderWidth[0] }}
                    fontSize="14px"
                  >
                    <Box display={'flex'} alignItems={'center'} gap={'10px'} marginLeft={'24px'}>
                      <CheckboxStyled
                        boxSize={'16px'}
                        left={'40%'}
                        checkIconWidth={3}
                        checkIconHeight={7}
                        sx={{ padding: '4px' }}
                        checked={documentsStore.selectedRows?.has(file_id)}
                        onChange={() => handleCheckboxChange(file_id)}
                      />
                      <Box onClick={() => downloadDocumentById(row, file_id)}>{file_name}</Box>
                    </Box>
                  </TableCellStyledDocuments>
                  <TableCellStyledDocuments
                    verticalAlign={'middle'}
                    sx={{ width: tableHeaderWidth[1] }}
                    fontSize="14px"
                  >
                    {user_id}
                  </TableCellStyledDocuments>
                  <TableCellStyledDocuments
                    verticalAlign={'middle'}
                    sx={{ width: tableHeaderWidth[2] }}
                    fontSize="14px"
                  >
                    {dateFormatterDefault(file_date)}
                  </TableCellStyledDocuments>
                  <TableCellStyledDocuments
                    verticalAlign={'middle'}
                    sx={{ width: tableHeaderWidth[3] }}
                    fontSize="14px"
                  >
                    <MoreMenuTabData
                      onDelete={() => handleDeleteRow({ file_id, file_date, file_name, user_id })}
                      toDownloadById={true}
                      handleDownload={() => downloadDocumentById(row, file_id)}
                      padding={'4px'}
                    />
                  </TableCellStyledDocuments>
                </TableRowStyled>
              </Collapse>
            </TableCellStyled>
          </TableRowStyled>
        ))}
      </>
    );
  },
);

const RowTableBodyDocumentsApp = observer(
  ({
    row,
    filled,
    handleRowClick,
    handleDeleteRow,
    downloadDocumentById,
    handleCheckboxChange,
  }: {
    row: TableBodyDocumentsApplications;
    filled: boolean;
    handleRowClick: (id?: number) => void;
    handleDeleteRow: (row?: DocumentsFile | TableBodyDocuments) => void;
    downloadDocumentById: (row?: TableBodyDocuments, documentId?: string) => Promise<void>;
    handleCheckboxChange: (id: string) => void;
  }) => {
    const { documentsStore } = useRootStore();
    const isAppOpen = documentsStore.openRows?.has(row.id.toString());

    const isRowOpen = (documentId: number, parentId?: number) =>
      documentsStore.openRows?.has(`${parentId}_${documentId}`);

    return (
      <>
        <TableRowStyled
          key={row.id}
          sx={[
            {
              backgroundColor: filled ? color.light : color.transparent,
              borderBottom: isAppOpen ? `1px solid ${color.light_gray_v2}` : undefined,
            },
          ]}
          onClick={() => handleRowClick()}
        >
          <TableCellStyledDocuments verticalAlign={'middle'} sx={{ width: tableHeaderWidth[0] }}>
            <Box display={'flex'} alignItems={'center'}>
              <Box display={'flex'} alignItems={'center'} marginLeft={'-6px'} marginRight={'4px'}>
                {isAppOpen ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
              </Box>
              {row.id}
            </Box>
          </TableCellStyledDocuments>
          <TableCellStyledDocuments
            verticalAlign={'middle'}
            sx={{ width: tableHeaderWidth[1] }}
          ></TableCellStyledDocuments>
          <TableCellStyledDocuments
            verticalAlign={'middle'}
            sx={{ width: tableHeaderWidth[2] }}
          ></TableCellStyledDocuments>
          <TableCellStyledDocuments align="right" verticalAlign={'middle'} sx={{ width: tableHeaderWidth[3] }}>
            <Box sx={{ visibility: 'hidden' }}>
              <MoreMenuTabData padding={'4px'} />
            </Box>
          </TableCellStyledDocuments>
        </TableRowStyled>

        {row.result?.map(({ document_id_vid, name_vid, files }) => (
          <TableRowStyledWithoutPadding key={document_id_vid}>
            <TableCellStyled sx={{ padding: 0 }} colSpan={tableHeader.length}>
              <Collapse in={isAppOpen} timeout={600} unmountOnExit>
                <TableRowStyled
                  key={document_id_vid}
                  sx={[
                    {
                      backgroundColor: color.transparent,
                      borderBottom: `1px solid ${color.light_gray_v2}`,
                    },
                  ]}
                  onClick={() => handleRowClick(document_id_vid)}
                >
                  <TableCellStyledDocuments verticalAlign={'middle'} sx={{ width: tableHeaderWidth[0] }}>
                    <Box display={'flex'} alignItems={'center'} paddingLeft={'16px'}>
                      <Box display={'flex'} alignItems={'center'} marginLeft={'-6px'} marginRight={'4px'}>
                        {isRowOpen(document_id_vid, row.id) ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                      </Box>
                      {name_vid}
                      {files?.length && (
                        <Typography
                          sx={{
                            marginLeft: '5px',
                            fontWeight: 500,
                            fontSize: '16px',
                            lineHeight: '18.78px',
                            color: color.grayMain,
                          }}
                        >
                          ({files?.length})
                        </Typography>
                      )}
                    </Box>
                  </TableCellStyledDocuments>
                  <TableCellStyledDocuments
                    verticalAlign={'middle'}
                    sx={{ width: tableHeaderWidth[1] }}
                  ></TableCellStyledDocuments>
                  <TableCellStyledDocuments
                    verticalAlign={'middle'}
                    sx={{ width: tableHeaderWidth[2] }}
                  ></TableCellStyledDocuments>
                  <TableCellStyledDocuments align="right" verticalAlign={'middle'} sx={{ width: tableHeaderWidth[3] }}>
                    <MoreMenuTabData
                      onDelete={() => handleDeleteRow({ document_id_vid, name_vid, files })}
                      toDownloadById={true}
                      handleDownload={() => downloadDocumentById({ document_id_vid, name_vid, files })}
                      padding={'4px'}
                    />
                  </TableCellStyledDocuments>
                </TableRowStyled>

                {files?.map(({ file_id, file_date, file_name, user_id }) => (
                  <TableRowStyled key={file_id}>
                    <TableCellStyled sx={{ padding: 0 }} colSpan={tableHeader.length}>
                      <Collapse in={isRowOpen(document_id_vid, row.id)} timeout={600} unmountOnExit>
                        <TableRowStyled sx={{ borderBottom: `1px solid ${color.light_gray_v2}` }}>
                          <TableCellStyledDocuments
                            verticalAlign={'middle'}
                            sx={{ minWidth: tableHeaderWidth[0] }}
                            fontSize="14px"
                          >
                            <Box display={'flex'} alignItems={'center'} gap={'10px'} marginLeft={'24px'}>
                              <CheckboxStyled
                                boxSize={'16px'}
                                left={'40%'}
                                checkIconWidth={3}
                                checkIconHeight={7}
                                sx={{ padding: '4px' }}
                                checked={documentsStore.selectedRows?.has(file_id)}
                                onChange={() => handleCheckboxChange(file_id)}
                              />
                              <Box onClick={() => downloadDocumentById({ document_id_vid, name_vid, files }, file_id)}>
                                {file_name}
                              </Box>
                            </Box>
                          </TableCellStyledDocuments>
                          <TableCellStyledDocuments
                            verticalAlign={'middle'}
                            sx={{ width: tableHeaderWidth[1] }}
                            fontSize="14px"
                          >
                            {user_id}
                          </TableCellStyledDocuments>
                          <TableCellStyledDocuments
                            verticalAlign={'middle'}
                            sx={{ width: tableHeaderWidth[2] }}
                            fontSize="14px"
                          >
                            {dateFormatterDefault(file_date)}
                          </TableCellStyledDocuments>
                          <TableCellStyledDocuments
                            verticalAlign={'middle'}
                            sx={{ width: tableHeaderWidth[3] }}
                            fontSize="14px"
                          >
                            <MoreMenuTabData
                              onDelete={() => handleDeleteRow({ file_id, file_date, file_name, user_id })}
                              toDownloadById={true}
                              handleDownload={() => downloadDocumentById({ document_id_vid, name_vid, files }, file_id)}
                              padding={'4px'}
                            />
                          </TableCellStyledDocuments>
                        </TableRowStyled>
                      </Collapse>
                    </TableCellStyled>
                  </TableRowStyled>
                ))}
              </Collapse>
            </TableCellStyled>
          </TableRowStyledWithoutPadding>
        ))}
      </>
    );
  },
);

const RenderBodyDocumentsTable = observer(
  ({
    row,
    filled,
    handleOpenDeleteModal,
  }: {
    row: TableBodyDocuments | TableBodyDocumentsApplications;
    filled: boolean;
    handleOpenDeleteModal: (value: TableBodyDocuments | DocumentsFile) => void;
  }) => {
    const { documentsStore } = useRootStore();

    const handleRowClick = (id?: number) => {
      let rowId: string | undefined;

      if ('name_vid' in row) {
        rowId = row.document_id_vid.toString();
      } else if ('id' in row && !id) {
        rowId = row.id.toString();
      } else if (id && 'id' in row) {
        rowId = `${(row as { id: number }).id}_${id}`;
      }

      if (rowId) {
        documentsStore.toggleRow(rowId);
      }
    };

    const handleDeleteRow = (row?: DocumentsFile | TableBodyDocuments) => {
      row && handleOpenDeleteModal(row);
    };

    const downloadDocumentById = async (row?: TableBodyDocuments, documentId?: string) => {
      const documentsId: string[] = documentId ? [documentId] : row?.files?.map((el) => el.file_id) || [];

      if (documentsId.length > 0) {
        await documentsStore.downloadDocuments({ documentsId });
      }
    };

    const handleCheckboxChange = (id: string) => {
      documentsStore.toggleRowSelection(id);
    };

    return 'name_vid' in row ? (
      <RowTableBodyDocuments
        row={row}
        filled={filled}
        handleRowClick={handleRowClick}
        handleDeleteRow={handleDeleteRow}
        handleCheckboxChange={handleCheckboxChange}
        downloadDocumentById={downloadDocumentById}
      />
    ) : (
      <RowTableBodyDocumentsApp
        row={row}
        filled={filled}
        handleRowClick={handleRowClick}
        handleDeleteRow={handleDeleteRow}
        downloadDocumentById={downloadDocumentById}
        handleCheckboxChange={handleCheckboxChange}
      />
    );
  },
);

export const ContractDocumentsTable = observer(() => {
  const { documentsStore, isLoading } = useRootStore();
  const {
    open,
    handleClose,
    handleOpen,
    deletedValue: document,
  } = useModalDelete<TableBodyDocuments | DocumentsFile | string[]>();
  const { id } = useParams<{ id: string }>();

  const handleDelete = () => {
    if (id && document) {
      let documentsId = [];
      if (Array.isArray(document)) {
        documentsId = document;
      } else if ('files' in document) {
        documentsId = document.files.map((file) => file.file_id);
      } else {
        documentsId = [document.file_id];
      }
      documentsStore.deleteDocuments({ documentsId });
    }
    handleClose();
  };

  const { title, description } = getModalText(document);

  return (
    <>
      <TableTemplateDocuments<TableBodyDocuments | TableBodyDocumentsApplications>
        tableHeader={tableHeader}
        tableHeaderWidth={tableHeaderWidth}
        RenderComponent={(props) => <RenderBodyDocumentsTable {...props} handleOpenDeleteModal={handleOpen} />}
        store={documentsStore}
        isLoading={isLoading}
        isPageApplicationTable={true}
        handleOpenDeleteModal={handleOpen}
      />

      <ModalDelete
        uniqId="Data_table_modal"
        open={open}
        handleClose={handleClose}
        handleDelete={handleDelete}
        title={title}
        descriptions={description}
      />
    </>
  );
});
