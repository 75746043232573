import React, { Dispatch, useState, useEffect } from 'react';

import { FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';

import { InputBaseOutlinedStyledStatus } from '@/components/atoms/TextFieldStyled';
import { color } from '@/constants/colors';
import { statusSignalColorMap, statusSignalLabelColorMap } from '@/constants/backendEnum';

type SelectItemsType = {
  IDENTIFIED: string;
  NOT_CONFIRM: string;
  CONFIRM: string;
  REVIEWED_KK: string;
  ARCHIVE: string;
};

type ModalProps = {
  filterLabel?: string;
  selectTitle?: string;
  activeStatusEn?: string;
  selectItems: SelectItemsType | string[];
  handleConfirm?: () => void;
  handleChangeStatus?: (event: any) => void;
  handleOpen?: () => void;
  handleClose?: () => void;
  handleResetStatus?: (event: React.MouseEvent) => void;
  statusName?: string[];
  activeStatus?: string | string[];
  setActiveStatus?: Dispatch<React.SetStateAction<string | string[]>>;
  setActiveStatusEn?: Dispatch<React.SetStateAction<string>>;
  open?: boolean;
  saveForm?: () => Promise<void>;
  statusSignal?: string;
};

export const useModalSelectingStatus = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return {
    open,
    handleOpen,
    handleClose,
  };
};

export const ModalSelectingStatus = ({
  filterLabel,
  selectTitle,
  selectItems,
  handleConfirm,
  handleChangeStatus,
  handleOpen,
  handleClose,
  handleResetStatus,
  statusName,
  activeStatus,
  setActiveStatus,
  setActiveStatusEn,
  open,
  saveForm,
  activeStatusEn,
  statusSignal,
}: ModalProps) => {
  const [selectedStatus, setSelectedStatus] = useState<string>();

  const handleMenuItemSelect = (event: any) => {
    setSelectedStatus(event.target.value);

    if (setActiveStatus) {
      setActiveStatus(event.target.value);
    }
    if (handleClose) {
      handleClose();
    }
    if (handleChangeStatus) {
      handleChangeStatus(event);
    }
  };

  useEffect(() => {
    if (Array.isArray(selectedStatus) && selectedStatus.length > 0 && typeof selectedStatus[0] === 'string') {
      const firstElement = selectedStatus[0];
      Object.entries(selectItems).forEach(([key, value]) => {
        if (
          typeof value === 'string' &&
          typeof firstElement === 'string' &&
          value.toLowerCase() === firstElement.toLowerCase()
        ) {
          setActiveStatusEn && setActiveStatusEn(key);
        }
      });
    }
  }, [selectedStatus]);

  useEffect(() => {
    if (saveForm && activeStatusEn !== '') {
      saveForm();
    }
  }, [activeStatusEn]);

  return (
    <FormControl
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: statusSignalColorMap[statusSignal || ''] || color.gray_v2,
        borderRadius: '5px',
        height: '30px',
        width: 'auto',
      }}
    >
      <InputLabel
        shrink={false}
        sx={{
          fontSize: '12px',
          fontWeight: '500',
          textTransform: 'uppercase',
          padding: '5px',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%,-50%)',
          color: statusSignalLabelColorMap[statusSignal || ''] || color.black,
        }}
        id="demo-multiple-checkbox-label-status"
      >
        {activeStatus}
      </InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label-status"
        id="demo-multiple-checkbox-status"
        multiple
        value={statusName}
        onChange={handleMenuItemSelect}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        input={<InputBaseOutlinedStyledStatus />}
        renderValue={() => ''}
        IconComponent={() => <></>}
        MenuProps={{
          PaperProps: {
            style: {
              marginTop: '18px',
              padding: '10px 28px 18px 26px',
              borderRadius: '14px',
              boxShadow: '0 4px 16px rgba(0, 0, 0,0.1)',
            },
          },
        }}
        sx={{
          fontSize: '12px',
          padding: '0',
          border: 'none',
          width: '100%',
        }}
      >
        <h3 style={{ margin: '6px 0 12px 5px' }}>{selectTitle}</h3>
        {Object.entries(selectItems).map(([key, value]) => {
          if (value.toLowerCase() !== activeStatus) {
            return (
              <MenuItem
                key={key}
                value={value}
                sx={{
                  padding: '0 16px 0 0px',
                  height: '33px',
                  gap: '0px',
                  '&.Mui-selected': {
                    backgroundColor: color.transparent,
                  },
                }}
              >
                <ListItemText
                  primary={String(value)}
                  primaryTypographyProps={{
                    style: { fontSize: '13px', color: color.black, fontWeight: '500', marginLeft: '2px' },
                  }}
                />
              </MenuItem>
            );
          }
        })}
      </Select>
    </FormControl>
  );
};
