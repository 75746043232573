import React from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useRootStore } from '@/stores';
import { DrawerLikeModal } from '@/components/common/DrawerLikeModal';
import { Dropdown } from '@/components/atoms/Dropdown';
import { DOCUMENT_TYPE, DOCUMENT_TYPE_KEY } from '@/constants/backendEnum';

import { useValidation } from '@/hooks/useValidation';
import { Box } from '@mui/material';
import { FileUploader } from '@/components/atoms/FileUploader';
import { InputLabelStyled } from '@/components/atoms/InputLabelStyled';

type Props = {
  openAdd: boolean;
  toggleOpen: () => void;
};

const templateDropdownDocumentsType = [
  {
    label: DOCUMENT_TYPE['1'],
    value: DOCUMENT_TYPE_KEY['1'],
  },
  {
    label: DOCUMENT_TYPE['2'],
    value: DOCUMENT_TYPE_KEY['2'],
  },
  {
    label: DOCUMENT_TYPE['3'],
    value: DOCUMENT_TYPE_KEY['3'],
  },
  {
    label: DOCUMENT_TYPE['5'],
    value: DOCUMENT_TYPE_KEY['5'],
  },
  {
    label: DOCUMENT_TYPE['6'],
    value: DOCUMENT_TYPE_KEY['6'],
  },
];

export const ContractDocumentsModalCreate = observer(({ openAdd, toggleOpen }: Props) => {
  const { isLoading, documentsStore, userStore, applicationsStore } = useRootStore();
  const id = documentsStore.contragentId;

  const sourceContext = documentsStore.sourceContext;

  const { setFormHasError, value, onChangeDropdown, formHasErrorRaw, isError, handleChangeInput, clear } =
    useValidation();

  const handleToggleOpen = () => {
    clear();
    toggleOpen();
  };

  const handleSaveForm = async () => {
    const document_type = sourceContext === 'application' ? 5 : +value.document_type;
    const document_kind = +value.document_kind;
    const application_number = value.application_number;
    const file = value['file'] as File[];
    const userId = userStore.results?.pk;

    if (id) {
      documentsStore
        .createDocuments({
          id,
          modelId:
            sourceContext === 'contragent' ? (application_number ? application_number : id) : documentsStore.modelId,
          userId,
          documentType: document_type,
          documentKind: document_kind,
          file,
        })
        .then(() => {
          toggleOpen();
        });
    }
  };

  const handleChangeFile = (value: File) => {
    handleChangeInput({ file: value });
  };

  const selectedType = sourceContext === 'contragent' ? value.document_type : '5';

  const getDropDownType =
    selectedType && documentsStore.documentsDropdownValue
      ? documentsStore.documentsDropdownValue[selectedType - 1]
      : [];

  const getApplicationDropDownType = applicationsStore.createApplicationsDropdownValue ?? [];

  return (
    <>
      <DrawerLikeModal
        title="Добавить документ"
        open={openAdd}
        toggleOpen={handleToggleOpen}
        saveForm={handleSaveForm}
        buttonConfirmText="Создать документ"
        isDisabled={isError || value?.file?.length === 0}
        isLoading={isLoading}
        isFormUpload={true}
      >
        {sourceContext === 'contragent' && (
          <Dropdown
            handleGlobalError={setFormHasError}
            onChange={onChangeDropdown}
            required
            isError={formHasErrorRaw?.document_type ?? false}
            key="document_type"
            label="Тип документа ⃰"
            title="Выберите документ"
            id="document_type"
            dropdownItems={templateDropdownDocumentsType}
            value={documentsStore.selectedFilter?.type ?? '1'}
          />
        )}
        <Dropdown
          handleGlobalError={setFormHasError}
          onChange={onChangeDropdown}
          required
          isError={formHasErrorRaw?.document_kind ?? false}
          key="document_kind"
          label="Вид документа ⃰"
          title="Выберите документ"
          id="document_kind"
          dropdownItems={getDropDownType}
        />
        {selectedType === '5' && sourceContext === 'contragent' && (
          <Dropdown
            handleGlobalError={setFormHasError}
            onChange={onChangeDropdown}
            required
            isError={formHasErrorRaw?.application_number ?? false}
            key="application_number"
            label="Заявка ⃰"
            title="Выберите заявку"
            id="application_number"
            dropdownItems={getApplicationDropDownType}
          />
        )}
        <InputLabelStyled>Прикрепить файл ⃰</InputLabelStyled>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '50%',
          }}
        >
          <FileUploader
            sx={{ marginRight: '24px', flex: '1 0 auto', marginBottom: '22px' }}
            handleFile={handleChangeFile}
            hideInput
          />
        </Box>
      </DrawerLikeModal>
    </>
  );
});
